import { useState,useContext } from 'react';
import { SmartContext } from "../../../../../../library/Core/SmartContext";

const EditDemographicssFormLayout = (props:any) => {
    console.log("props",props)
    const { state, dispatch } = useContext(SmartContext);
    const showFlag = state?.modalFlags?.showDemoGraphicsModal;
    console.log("showFlag",showFlag)

    const handleCloseModal = () => {
        //this logic apply when you want close modal but errro occured with value change added by vrinda #UAS-1239 @31Jan25
        const { visibleControl } = state.data;
        console.log("visibleControl_modal", visibleControl);
      
        const commentErrorField = `${visibleControl}Comment`;  
        const commentSpecificError = state.formValidationErrors[`demographics.${commentErrorField}`] || [];
        console.log("commentSpecificError", commentSpecificError);
    
        if (commentSpecificError.length > 0) {
            const currentFieldValue = state.data.demographics[visibleControl];  
            const markerFieldValue = state.data.marker.demographics[visibleControl];  
            console.log("currentFieldValue !== markerFieldValue",currentFieldValue," "+markerFieldValue)
            if (currentFieldValue !== markerFieldValue) {
                console.log(`Resetting ${visibleControl} value`);
                const updatedDemographics = {
                    ...state.data.demographics,
                    [visibleControl]: state.data.marker.demographics[visibleControl],  
                };
            
            if (JSON.stringify(state.data.demographics) !== JSON.stringify(updatedDemographics)) {
                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: {
                        dataKey: 'demographics',
                        value: updatedDemographics,  
                    },
                });
            }
        }
      }
    
        // Dispatch to close the modal
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showDemoGraphicsModal' } });
    };
    

    return (
       <div
            id="update-demographics-data"
            className={`modal fade ${showFlag ? 'show' : ''}`} // removed vh-100 and show class
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
            <div className="modal-content">
                <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                    <h5 className="modal-title" id="staticBackdropLabel">Add Remark </h5>
                    <button
                        type="button"
                        className="btn-close btn-close-small"
                        onClick={handleCloseModal}  // Call the handleCloseModal function here
                        aria-label="Close"
                    ></button>
                    </div>
                <div className="modal-body p-4"> {props.children}</div>
            </div>
        </div>
      </div>
    );
};  

export default EditDemographicssFormLayout;