import Swal from 'sweetalert2';
import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';
import { isEmpty } from '../../library/Core/SmartFunctions';

class WidgetService {
    static async pageLoader(params: any) {
        const state: any = {};
        const { id, pageName, code } = params;
        const isEditing = !!id;

        let internal: any = {};
        let data: any = {};
        let wgData: any = {};

        const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetlist`);
        const widgetList = response.data;
        
        const allowed_codes: any = widgetList.map((rec:any) => rec?.code);

        try {
            if ( ! allowed_codes.includes(code))  {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "You're on wrong page, Please click on Configure/Update button in widget listing page.",
                    footer: '<a href="/widget/listing">Go Back to listing page?</a>'
                  }).then(() => {
                    window.location.href = "/widget/listing";
                });
                throw "Err: Widget Code is not recognised..!"
            }
        }catch (error) {
            console.error('Error fetching program data:', error);
            return false
        }

        internal.widget_code = code;
        internal.widget_name = widgetList.find((rec: any) => rec.code == code)?.name;



        if (isEditing) {
            const widgetResp = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidget/${id}`);
            wgData = widgetResp?.data?.wgdata;
            data = widgetResp?.data?.wginfodata?.data;
            
            if (!wgData || isEmpty(wgData)) {
                throw new Error('Widget data is undefined');
            }
            // let widgetData = '{"metricData": [{"value": "15", "rowUUID": "41b5cf57-ad9f-48ba-a422-af215c9c245e", "metricType": "override", "fieldOfStudy": "13"}, {"value": 1, "rowUUID": "55722e50-4732-4a7f-9c1f-d089815a0911", "metricType": "actual", "fieldOfStudy": "1"}, {"value": "20", "rowUUID": "77a0635f-2889-49cf-b784-c9d99d341d75", "metricType": "override", "fieldOfStudy": "37"}, {"value": "30", "rowUUID": "b6b8c55b-13b3-416b-a249-7e160aa08b6c", "metricType": "override", "fieldOfStudy": "265"}, {"value": 5, "rowUUID": "02092249-9cbc-4006-9f64-a4b04570ceea", "metricType": "actual", "fieldOfStudy": "254"}, {"rowUUID": "82abf8c3-3df6-4002-a393-ea27d5e0622b", "metricType": "actual"}], "widgetData": {"other": 28, "heading": "Most Popular Careers", "chartType": "vertical_bars"}}';
            // data = JSON.parse(widgetData);
            internal.wgData = wgData;
        }

        switch (code) {
            case 'top_careers':
                // get  top career data
                const topCareersCountResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-career-counts/UG`);
                const countsData = topCareersCountResponse.data;
                internal.top_careers = countsData;

                data = {
                    ...data, 
                    countData: countsData, 
                    metricData: data?.metricData || [], 
                    widgetData: data.widgetData || {...data.widgetData, heading:"",  widgetName:"", instituteList:"", primaryColor:"", secondaryColor:"", displayEnquiry:"", formlist:""}
                }
                break;

            case 'top_study_country':
                // get  top study country data
                const topStudyCountryResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-study-country-counts/UG`);
                const topStudyCountryCountsData = topStudyCountryResponse.data;
                internal.top_study_country = topStudyCountryCountsData;

                data = {
                    ...data,
                    countData: topStudyCountryCountsData,
                    metricData: data?.metricData || [], 
                    widgetData: data.widgetData || {...data.widgetData, heading:""}
                }
                break;
        
            case 'current_location_country':
                // get  top study country data
                const currentLocationCountryResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-current-location-country-counts`);
                const currentLocationCountryCountsData = currentLocationCountryResponse.data;
                internal[code] = currentLocationCountryCountsData;

                data = {
                    ...data,
                    countData: currentLocationCountryCountsData,
                    metricData: data?.metricData || [], 
                    widgetData: data.widgetData || {...data.widgetData, heading: 'Alumni Based Worldwide', chartType: 'vertical_bars'}
                }
                break;

            case 'top_industries':
                // get top industry data
                const topIndustriesCountResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-industry-counts`);
                const IndustriesCountsData = topIndustriesCountResponse.data;
                internal.top_industries = IndustriesCountsData;

                data = {
                    ...data, 
                    countData: IndustriesCountsData, 
                    metricData: data?.metricData || [], 
                    widgetData: data.widgetData || {...data.widgetData, heading:"Alumni Contributing to Various Industries", chartType: 'vertical_bars'}
                }
                break;
            
            case 'alumni_occupation':
                // get top industry data
                const alumniOccupationCountResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-occupation-counts`);
                const AlumniOccupationCountsData = alumniOccupationCountResponse.data;
                internal[code] = AlumniOccupationCountsData;

                data = {
                    ...data, 
                    countData: AlumniOccupationCountsData, 
                    metricData: data?.metricData || [], 
                    widgetData: data.widgetData || {...data.widgetData, heading:"Occupation of our Alumni", chartType: 'pie_chart'}
                }
                break;
    
            case 'top_companies':
                // get  top companies data
                const topCompaniesResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-company-counts`);
                const topCompaniesCountsData = topCompaniesResponse.data;
                internal.top_companies = topCompaniesCountsData;

                const topCompanyListResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-company-list`);
                const topCompanyList = topCompanyListResponse.data;
                internal.top_company_list = topCompanyList;
                internal.isAddModalOpen = false;
                internal.isListModalOpen = false;
                
                data = { 
                    ...data, 
                    countData: topCompaniesCountsData, 
                    metricData: data?.metricData || [{metricCount:"0 Companies"}],
                    widgetData: data?.widgetData || {...data.widgetData, largeHeading:"", mediumHeading: "", otherDisp: "", sourceType: "", menuTabs: '', gridLayout: '', othersLbl: ''} 
                }
                break;

            case 'top_colleges':
                // get  top colleges data
                const topCollegesResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-college-counts/UG`);
                const topCollegesCountsData = topCollegesResponse.data;
                internal.top_colleges = topCollegesCountsData;

                const topCollegeListResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-top-college-list/UG`);
                const topCollegeList = topCollegeListResponse.data;
                internal.top_college_list = topCollegeList;
                internal.isAddModalOpen = false;
                internal.isListModalOpen = false;
                
                data = { 
                    ...data, 
                    countData: topCollegesCountsData, 
                    metricData: data?.metricData || [{metricCount:"0 Colleges"}],
                    widgetData: data?.widgetData || {...data.widgetData, largeHeading:"", mediumHeading: "", otherDisp: "", sourceType: "", menuTabs: '', gridLayout: '', othersLbl: ''} 
                }
                break;
        
            case 'directory_stats':
                // get  directory stats data
                const directoryStatsResponse = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/get-directory-stats`);
                const directoryStatsCountsData = directoryStatsResponse.data;
                internal.directory_stats = directoryStatsCountsData;
               
                data = { 
                    ...data, 
                    countData: directoryStatsCountsData, 
                    metricData: data?.metricData || [{metricCount:""}],
                    widgetData: data?.widgetData || {...data.widgetData, mediumHeading: ""} 
                }
                break;

            case 'appeal_video':                
                data = { 
                    ...data, 
                    widgetData: data?.widgetData || {...data.widgetData, videoUrl: "", thumbnail: "", aspectRatio: "" }
                }
                break;
            
            case 'cover_banner':                
                data = { 
                    ...data, 
                    widgetData: data.widgetData || {...data.widgetData, widgetName:"", instituteList:"", primaryColor:"", secondaryColor:""},
                    bannerData: data?.bannerData || [{coverImage: "", largeHeading: "", mediumHeading: "", introLink: "" }]
                }
            break;
            
            case 'enquiry_form':                
                data = { 
                    ...data, 
                    widgetData: data?.widgetData || {...data.widgetData, openButtonLayout: "vertical"},
                    formConfig: data?.formConfig || {
                        ...data.formConfig, applicablePrograms: [], customPrograms: [],
                        name: true, contactNumber: true, contactEmail: true
                    }
                }
            break;

            case 'admission_referral_form':                
                data = { 
                    ...data, 
                    widgetData: data?.widgetData || {...data.widgetData, openButtonLayout: "vertical"},
                    formConfig: data?.formConfig || {
                        ...data.formConfig, applicablePrograms: [], customPrograms: [],
                        name: true, contactNumber: true, contactEmail: true
                    }
                }
            break;
        }

        try {
            const config = await this.getPageConfig(code);
            state.formConfig = config;
            state.routeInfo = { id, pageName };
            state.data = data;
            state.internal = internal;
            return Promise.resolve(state);
        } catch (err) {
            logger.log(`Error in loading data for ${pageName} and the error is:`);
            console.error(err);
            return Promise.reject(err);
        }
    }
    
    /**
     * Retrieves the page configuration for a specific widget based on its code.
     *
     * @param code - The unique identifier for the widget.
     * @returns A Promise that resolves with the page configuration or rejects with an error.
     *
     * @remarks
     * This function attempts to import the page configuration JSON file for the given widget code.
     * If the configuration is successfully imported, it is returned as the resolved value.
     * If the configuration file is not found, an error is thrown and caught, resulting in a user-friendly error message displayed using SweetAlert2.
     * The error message includes a link to the widget listing page, allowing the user to navigate back.
     */
    static async getPageConfig(code: any) {
        // return Promise.resolve(eval(`pageConfig_${code}`));
        try {
            const config = await import(`./form_config/pageConfig_${code}.json`);
            return Promise.resolve(config.default);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "This Widget Is Under Development",
                footer: '<a href="/widget/listing">Go Back to listing page?</a>'
            }).then(() => {
                window.location.href = "/widget/listing";
            });
            return Promise.reject(new Error(`No configuration found for code: ${code}`));
        }
    }
}
export default WidgetService;
