import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';
import { axiosClient } from '../../library/Service/axiosClient';

import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../library/Service/domainService'
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';
//import UploadIcon from '../../../styles/images/gall_form_icon.png';

import { useNavigate } from 'react-router';
import FacebookAccount from './FacebookAccount';
import LinkedInAccount from './LinkedInAccount';
import InstagramAccount from './InstagramAccount';

import AuthorizedAccounts from './AuthorizedAccounts';
import { arrayColumn, getMultiAssociativeArray } from '../../library/Core/SmartFunctions';
import { SmartContext } from '../../library/Core/SmartContext';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
const networkTypeMap: any = {
    facebook: "Facebook",
    instagram: "Instagram",
    // linkedin: "LinkedIn",
    // twitter: "Twitter"
}

const ManageSocialIntegrations: React.FC = () => {    
    const navigate = useNavigate();
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
        },
    } as any);
    const currentTime = new Date();
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);

    let sesinstituteId = useState<string>(''); 
    // let userId = useState<string>('');
    const defaultPage = 'facebook';
    const [selectedPage, setSelectedPage] = useState(defaultPage);
    
    const [networkTypes, setNetworkTypes] = useState<any[]>([]);
	const [socialNetworkPages, setSocialNetworkPages] = useState<any[]>([]);
	const [uniqueNetworkTypes, setUniqueNetworkTypes]: any = useState<any[]>([]);

    // const queryParams = new URLSearchParams(window.location.search);
    
    // const eventId = queryParams.get('id');
    // console.log(eventId)
    
    // let school_settings:any = JSON.parse(localStorage.getItem('school_settings') ?? '{}');      
    //console.log(school_settings)  
    
    const userDataString = sessionStorage.getItem('user');
    
   //console.log(userDataString,'test kjhjk');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.institutes; 
        // userId = userData.userId;       
    }
    
    useEffect(() => {
		fetchData();       
    }, []);

    const fetchData = async () => {
        const response: any = await getPageData();
        const networkPageData = getMultiAssociativeArray(response.data, 'network_type');
        console.log(response, " response")
        console.log(networkPageData, " networkPageData");

        setNetworkTypes(Object.keys(networkPageData));
        setSocialNetworkPages(networkPageData);
        
        // Filter only active records before processing
        // const activeRecords = response.data.filter((item: any) => item.status === 'Active');

        // Set the filtered social network pages data
        // setSocialNetworkPages(activeRecords);

        // Extract unique network types from the active records
        // const uniquePages = Array.from(
        // 	new Set(activeRecords.map((item: any) => item.network_type))
        // );
        // setUniqueNetworkTypes(uniquePages);
        // setNetworkType(defaultPage)
    };

    const getPageData = async () => {
            try {
                const params = {
                    'institute_id': sessionState?.institutes?.join(',')
                }
                // const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-network-pages`, params);
                const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/get-pages`, params);
                
                const responseData = await response.data;
                return responseData;
            } catch (error) {
                console.error('Error fetching data:', error);
                return [];
            }
        };
    
 console.log(socialNetworkPages, " socialNetworkPages ==")
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className="main flex-1 d-flex">
                <div className="container max-1140 flex-1 px-lg-0">
                    <div className="flex-1 d-flex flex-column h-100 w-100">
                        <div className="white-block white-block-notopborderradius h-100 p-0 pt-2 mb-5">
                            <div className="custom-border-gray p-3 p-sm-4 pb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mx-0">
                                    <h3 className="font-35 font-600 mb-0 block-title">Manage Social Integrations</h3>
                                </div>
                            </div>

                            <div className="job_internship_tab polartabsec radius-6 m-0 px-4">
                                <div className="xscroll p-0 ms-0">
                                    <ul className="nav nav-tabs social-integrations" id="JobInternshipTab" role="tablist">
                                        {/* {Object.keys(networkTypeMap).map((networkType: any, index: number) => ( */}
                                        {Object.entries(networkTypeMap).map(([networkType, value]: any, index) => (
                                            <li className="nav-item" role="presentation" key={index}>
                                                <button
                                                    className={`nav-link ${selectedPage == networkType ? 'active' : ''}`}
                                                    id={`${networkType}-tab`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#${networkType}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={networkType}
                                                    aria-selected={selectedPage == networkType ? 'true' : 'false'}
                                                    onClick={() => setSelectedPage(networkType)}>
                                                    <i className={networkType}></i>
                                                    {value}
                                                </button>
                                            </li>
                                        ))}
                                        {/* <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${selectedPage == 'facebook' ? 'active' : ''}`}
                                                id="facebook-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#facebook"
                                                type="button"
                                                role="tab"
                                                aria-controls="facebook"
                                                aria-selected={selectedPage == 'facebook' ? 'true' : 'false'}
                                                onClick={() => setSelectedPage('facebook')}>
                                                >
                                                <i className="facebook"></i>Facebook
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${selectedPage == 'linkedIn' ? 'active' : ''}`}
                                                id="linkedIn-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#linkedIn"
                                                type="button"
                                                role="tab"
                                                aria-controls="linkedIn"
                                                aria-selected={selectedPage == 'linkedin' ? 'true' : 'false'}
                                                onClick={() => setSelectedPage('linkedin')}>
                                                >
                                                <i className="linkedin"></i> LinkedIn{' '}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${selectedPage == 'instagram' ? 'active' : ''}`}
                                                id="instagram-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#instagram"
                                                type="button"
                                                role="tab"
                                                aria-controls="instagram"
                                                aria-selected={selectedPage == 'instagram' ? 'true' : 'false'}
                                                onClick={() => setSelectedPage('instagram')}>
                                                >
                                                <i className="instagram"></i>Instagram
                                            </button>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="p-3 p-md-4">
                                <div className="authorized_accounts mt-2">
                                    <h2 className="font-20 font-500">Authorized Accounts</h2>
                                    <div className="tab-content" id="pills-tabContent">
                                        {/* {socialNetworkPages?.map((networkPage: any, index: number) => ( */}
                                        {Object.entries(networkTypeMap).map(([networkType, value]: any, index) => (
                                            <AuthorizedAccounts
                                                key={index}
                                                networkType={networkType}
                                                selectedPage={selectedPage}
                                                data={socialNetworkPages[networkType]}
                                                fetchData={fetchData}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SmartContext.Provider>
    );
};
export default ManageSocialIntegrations;