import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import { axiosClient } from '../../../library/Service/axiosClient';

const ManageProfileMarkerList = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);
    
    const { data } = useLoaderData() as State;
    const [syncing, setSyncing] = useState<Set<string>>(new Set()); // Track syncing states for each institute ID

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { data },
        });
    }, [data]);

    const syncNow = async (instituteId: string) => {
    if (syncing.has(instituteId)) return; // Prevent multiple clicks while syncing
    const updatedSyncing = new Set(syncing); // Create a new set to avoid mutation of state
    updatedSyncing.add(instituteId); // Add to syncing
    setSyncing(updatedSyncing);

    try {
        const response = await axiosClient().get(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/sync-marker-log-by-institute-id/${instituteId}`);
        if (response?.status === 200) {
            alert(response?.data?.message);
        }
    } catch (error) {
        console.error("Error syncing:", error);
    } finally {
        const updatedSyncing = new Set(syncing); // Create a new set for state update
        updatedSyncing.delete(instituteId); // Remove from syncing
    }
};


    const isOlderThan30Days = (dateString: string | null | undefined) => {
        if (!dateString || dateString.trim() === '') return true; // Handles null, undefined, or empty string

        const logDate = new Date(dateString);
        if (isNaN(logDate.getTime())) return true; // Invalid date check

        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - logDate.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return daysDifference > 30;
    };

    const formatDate = (dateString: string | null | undefined): string => {
        if (!dateString) return '';
        
        const date = new Date(dateString);
        
        if (isNaN(date.getTime())) return ''; // Invalid date check
        
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based
        const year = date.getFullYear();
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);
        
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };
    

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                                        <div className="table-responsive attendee_dtl">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Logo</th>
                                                        <th className="text-center">Name</th>
                                                        <th className="text-center">Last Synced At</th>
                                                        <th className="text-center">Sync Progress</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state?.data?.instituteList?.map((row: any) => (
                                                        <tr key={row.institute_id}>
                                                            <td>
                                                                <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                                                    <img src={row.logo} height="50px" alt="Institute Logo" />
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                                                    {row.name}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                                                    {formatDate(row.log_created_at)}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                                                {row.status != 'IN_PROGRESS' ? row.status : 'IN PROGRESS'}{row.total_count == null ? '-' : row.total_count > 0 ? `[${row.executed_count}/${row.total_count}]`: '[Not started yet]'}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="radio-group d-flex flex-column align-items-start lbl_orange font-14 font-500">
                                                                    <button
                                                                        disabled={syncing.has(row.institute_id) || !(!row.log_created_at || isOlderThan30Days(row.log_created_at))}
                                                                        onClick={() => syncNow(row.institute_id)}
                                                                        className="btn btn-green"
                                                                    >
                                                                        {"Sync Now"}
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </SmartContext.Provider>
    );
};

export default ManageProfileMarkerList;
