import { axiosClient } from '../../library/Service/axiosClient';

import { REPORT_CONFIG } from '../admin/reports/report-config';
class ManageWidgetsListService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManageWidgetsListService.getDefaultSearchCriteria();
        return Promise.all([
            this.search(searchCriteria),
        ]).then((values) => {
            state.data = {
                wedgitList: values[0].data ?? [],
                searchCriteria: { ...searchCriteria },
                //filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
           
            return Promise.resolve(state);
            
            
        });
    }

    static search(searchCriteria: any ): any {
        //const config = REPORT_CONFIG[pageName];
        const payload = ManageWidgetsListService.sanitizeSearchCriteria(searchCriteria,);
        console.log(payload, 'payload search list')
        //return axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetlist`);
        return axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetlist`, payload);
    }

    
    static sanitizeSearchCriteria(searchCriteria: any) {
        return searchCriteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            instituteId:''
        };
    }

    
    // static getWedgitlist() {
    //  return axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetlist`);
    // }

}

export default ManageWidgetsListService;