import { useContext, useEffect } from 'react';
import React, { useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import AlumniProfileList from './AlumniProfileList';
import AlumniProfileListExtra from './AlumniProfileListExtra';
import { axiosClient } from '../../../library/Service/axiosClient';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import SessionContext from '../../../library/Core/SessionContext';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { GlobalContext } from '../../../library/Core/GlobalContext';
//import { State as SmartTypesState } from '../../../library/Core/SmartTypes'; // Assuming SmartTypes is correctly imported
import smartReducer from '../../../library/Core/SmartReducer';
import useManagementDashboard from '../../management/dashboard/useManagementDashboard';
// other imports

interface Errors {
    largeHeading?: string;
    mediumHeading?: string;
    gridLayout?: string;
    widgetName ?: string;
    instituteList?: string;
}

// interface State {
//     formConfig: any; // Replace with actual type if available
//     data: any; // Replace with actual type if available
//     internal: any; // Replace with actual type if available
//     routeInfo: any; // Replace with actual type if available
// }
// interface State {
//     formConfig: any;  // Make it optional
//     data: any;
//     internal: any;
//     routeInfo: any;
//     pageDomain: any;
//     filters: any;
//     formValidationErrors: any;
//     actions: any;
// }
const GuidingAlumniList = () => {
    const { id, code } = useParams();
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    console.log(id, 'id')
    const navigate = useNavigate();
    const location = useLocation();
    const [largeHeading, setLargeHeading] = useState('');
    const [mediumHeading, setMediumHeading] = useState('');
    const [gridLayout, setGridLayout] = useState('2');
    const [OpenButtonLayout, setOpenButtonLayout] = useState('2');
    const [widgetHead, setwidgetHead] = useState('Guiding Alumni');
    const [widgetCode, setWidgetCode] = useState(code);
    const [widgetInstituteId, setWidgetInstituteId] = useState(id ? id : '');
    const [errors, setErrors] = useState<Errors>({});
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const [count, setCount] = useState(0); // replace this with actual count logic
    const { globalState } = useContext(GlobalContext);
    const [schoolName, setSchoolName] = useState('');
    const [primaryColor, setPrimaryColor] = useState("#0007d1");
    const [secondaryColor, setSecondaryColor] = useState("#ffffff");
    const [widgetName, setWidgetName] = useState<string>("");
    const { getCampuses } = useManagementDashboard();
    // State for selected campus and filtered data
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedForm, setSelectedForm] = useState('');
    const [filteredData, setFilteredData] = useState([]);     
    const [isFormRequired, setIsFormRequired] = useState(false);

   const campuses =(sessionState?.userTypeCode=='INSTITUTE_ADMIN') ?  getCampuses() : globalState.domain.get('INSTITUTE_DOMAIN')?.map((data) => {
        return {label:data.value, value:data.code};
    });
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},        
    } as any);

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        console.log(sessionState, 'sessionState');
    
        const institutes = sessionState?.institutes || [];
        const instituteGroupId = sessionState?.instituteGroupId || '';
        
        let instituteName = '';
    
        if (state && state.domain && getDomainValueForCode) {  // Ensure that state and the function are defined
            if (!institutes.includes(String(instituteGroupId))) {
                console.log("Group School Detected");
                instituteName = getDomainValueForCode(instituteGroupId, 'SCHOOL_GROUP', state);
                setSchoolName(instituteName);
            } else {
                console.log("Single School Detected");
                const primaryInstitute = sessionState?.primaryInstitute || '';
                instituteName = getDomainValueForCode(primaryInstitute, 'INSTITUTE_DOMAIN', state);
                setSchoolName(instituteName);
            }
        } else {
            console.warn("State or getDomainValueForCode is not ready yet.");
        }
    
        console.log("Institute Name:", instituteName);
        
        console.log(schoolName, 'schoolName')
    
    }, [sessionState, state]);

    useEffect(() => {
        

        if ( code == 'notable_alumni_mentors' ) {
            setwidgetHead('Notable Alumni')
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)
            } else {
                setLargeHeading(`${schoolName} Success Stories`)
                setMediumHeading('Here are some notable alumni who are out there making their dreams come true and making us proud too!')                
            }

            
        } else if ( code == 'alumni-speak' ) {
            setwidgetHead('Alumni Speak')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
                setGridLayout(String(data?.countData?.widgetData[0]?.grid_layout));

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)
            } else {

                setLargeHeading(`${schoolName} Alumni Speak`)
                setMediumHeading('Here is what our distinguished alumni have to say about their experience while studying at the institute.')
            }
           
        } else if ( code == 'guiding_alumni_mentors' ) {
            setwidgetHead('Guiding Alumni')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)
            } else {

                setLargeHeading(`${schoolName} Guiding Alumni`);
                setMediumHeading('We are proud of our alumni who are setting and example and motivating younger students. Here are some alumni who are actively guiding current students by answering questions, conducting webinars and much more.')
            }
           
        } else if ( code == 'eclectic_career_alumni' ) {
            setwidgetHead('Eclectic Career Alumni')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)
            } else {

                setLargeHeading(`Eclectic career Chosen by Our Alumni`);
                setMediumHeading('Here are some alumni who are breaking the societal norms to pursue their dreams and making us proud too!')
            }
           
        } else if ( code == 'entrepreneur_alumni' ) {
            setwidgetHead('Entrepreneur Alumni')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)
            } else {

                setLargeHeading(`${schoolName} Alumni as Entrepreneurs`);
                setMediumHeading('Here are some entrepreneur alumni who are out there making their dreams come true and making us proud too!')
            }
           
        } else if ( code == 'spotlight' ) {
            setwidgetHead('Alumni in Spotlight')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                //setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)
            } else {

                setLargeHeading(`Alumni in Spotlight`);
                //setMediumHeading('Here are some entrepreneur alumni who are out there making their dreams come true and making us proud too!')
            }
           
        } else if ( code == 'topper_zone' ) {
            setwidgetHead('Alumni in Topper Zone')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
                //alert(data?.countData?.widgetData[0]?.layout_type)
                setOpenButtonLayout(data?.countData?.widgetData[0]?.layout_type)
                //alert(OpenButtonLayout);

                setWidgetName(data?.countData?.widgetData[0]?.widget_name)
                setSelectedCampus(data?.countData?.widgetData[0]?.institute_id)
                setPrimaryColor(data?.countData?.widgetData[0]?.primary_color)
                setSecondaryColor(data?.countData?.widgetData[0]?.secondary_color)
                
                setIsFormRequired(Boolean(data?.countData?.widgetData[0]?.from_status));
                setSelectedForm(data?.countData?.widgetData[0]?.from_id)

            } else {

                setLargeHeading(`${schoolName}`);
                setMediumHeading('Have a look at the journey of our toppers over the years')
               // alert(OpenButtonLayout);
            }
           
        }
        GetFormList(selectedCampus);
        setCount(data?.countData?.widgetWiseListData?.length)
    }, [schoolName]);

    const [isActive, setIsActive]: any = useState({});

    const handlePreviewClick = () => {
        // Example validation logic for count
        if ( code == 'spotlight' ) {

            console.log(count, 'data?.countData?.widgetWiseListData?');
            if (count > 0 && count <= 4) {
                // Redirect to the preview URL
                const previewUrl = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${id}`;
                // Open the preview URL in a new tab
                window.open(previewUrl, '_blank');
            } else {
                // Handle the validation failure, e.g., show an alert or message
                alert('Count must be minimum 1 and maximum 4 to preview.');
            }

        } else if ( code == 'topper_zone' ) {

            console.log(count, 'data?.countData?.widgetWiseListData?');
            if (count < 8) {
                // Handle the validation failure, e.g., show an alert or message
                alert('Count must be minimum 8 to preview.');
                
            } else {
                // Redirect to the preview URL
                const previewUrl = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${id}`;
                // Open the preview URL in a new tab
                window.open(previewUrl, '_blank');
                
            }

        } else {

            console.log(count, 'data?.countData?.widgetWiseListData?');
            if (count > 3) {
                // Redirect to the preview URL
                const previewUrl = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${id}`;
                // Open the preview URL in a new tab
                window.open(previewUrl, '_blank');;
            } else {
                // Handle the validation failure, e.g., show an alert or message
                alert('Count must be greater than 4 to preview.');
            }

        }


        
    };

    

    const handleValidation = () => {
        let tempErrors: Errors = {};
        let isValid = true;

        // Constants for maximum lengths
        const maxLargeHeadingLength = 60;
        const maxMediumHeadingLength = 250;
        const maxWidgetNameLength = 100;

    // ** Validate Widget Name **
    if (!widgetName) {
        tempErrors.widgetName = "Widget Name is required";
        isValid = false;
    } else if (widgetName.trim() === "") {
        tempErrors.widgetName = "Widget Name cannot be empty or whitespace";
        isValid = false;
    } else if (widgetName.length > maxWidgetNameLength) {
        tempErrors.widgetName = `Widget Name cannot exceed ${maxWidgetNameLength} characters`;
        isValid = false;
    }

    // ** Validate Institute List (Campus Selection) **
    // if (!selectedCampus) {
    //     tempErrors.instituteList = "Please select a campus";
    //     isValid = false;
    // }
        if (!largeHeading) {
            tempErrors.largeHeading = "Large Heading is required";
            isValid = false;
        } else if (largeHeading.trim() === "") {
            tempErrors.largeHeading = "Large Heading cannot be empty or whitespace";
            isValid = false;
        } else if (largeHeading.length > maxLargeHeadingLength) {
            tempErrors.largeHeading = `Large Heading cannot exceed ${maxLargeHeadingLength} characters`;
            isValid = false;
        }
        // Validate medium heading only if the widget code is not 'spotlight'
        if (code !== 'spotlight') {
            if (!mediumHeading) {
                tempErrors.mediumHeading = "Medium Heading is required";
                isValid = false;
            } else if (mediumHeading.trim() === "") {
                tempErrors.mediumHeading = "Medium Heading cannot be empty or whitespace";
                isValid = false;
            } else if (mediumHeading.length > maxMediumHeadingLength) {
                tempErrors.mediumHeading = `Medium Heading cannot exceed ${maxMediumHeadingLength} characters`;
                isValid = false;
            }
        }

        setErrors(tempErrors);
        return isValid;
    };
    const handleGridLayoutChange = (e:any) => {
        setGridLayout(e.target.value);
        // Additional validation or state updates can be added here
    };

    const handleOpenButtonChange = (e:any) => {
        setOpenButtonLayout(e.target.value);        
        // Additional validation or state updates can be added here
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (handleValidation()) {
            try {
                const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/action/savewidget`, {
                    largeHeading,
                    mediumHeading,
                    gridLayout,
                    widgetCode,
                    OpenButtonLayout,
                    widgetInstituteId,
                    primaryColor,
                    secondaryColor,
                    widgetName,
                    selectedCampus,
                    isFormRequired,
                    selectedForm
                });
    
                // Axios automatically parses JSON response
                console.log('Success:', response.data.id);
                alert('added successfully')
                 // Redirect to the desired URL after success
                const newUrl = `${process.env.REACT_APP_APP_BASE_URL}/widget/configurelist/${widgetCode}/${response.data.id}`;
                window.location.href = newUrl;
                // You can add more actions on success, e.g., reset form, show a success message, etc.
            } catch (error: any) {
                if (error.response) {
                    // Server responded with a status other than 200 range
                    console.error('Response error:', error.response.data);
                    alert('Server returned an error response.');
                } else if (error.request) {
                    // Request was made but no response received
                    console.error('No response received:', error.request);
                    alert('No response received from the server.');
                } else {
                    // Something else caused the error
                    console.error('Error:', error.message);
                    alert('An error occurred. Please try again later.');
                }
            }
        }
    };
    
    const handleClick = (id: any) => {
        setIsActive((prevState: any) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };
    interface FormItem {
        label: string;
        value: string;
      }
    const [formList, setFormList] = useState<FormItem[]>([]);

    // Handle campus selection change
    const handleCampusChange = async (e: any) => {
        const selectedValue = e.target.value;
        setSelectedCampus(selectedValue);
        
        // ✅ Pass `selectedValue` directly to GetFormList to avoid stale state issues
        GetFormList(selectedValue);
    
        // Filter data based on selected campus
        const filtered = campuses.filter((item: any) => item.campus === selectedValue);
        setFilteredData(filtered);
    };
    const handleControlValueChange = ({
        control,
        value,
        dataKey,
        parentDataKey,
        state,
        dispatch,
    }: {
        control: string;
        value: boolean;
        dataKey: string;
        parentDataKey: string;
        state: any;
        dispatch: any;
    }) => {
        // Handle the control value change logic here
        console.log('Control:', control);
        console.log('Value:', value);
        console.log('Data Key:', dataKey);
        console.log('Parent Data Key:', parentDataKey);
        // Example logic: dispatch to update the state
        dispatch({
            type: 'UPDATE_CONTROL_VALUE',
            payload: {
                control,
                value,
                dataKey,
                parentDataKey,
            },
        });
    };
 

    // Handle checkbox change
    const handleFormRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setIsFormRequired(isChecked);
        // Initialize the necessary variables
        const control = 'formRequired';  // Example value, use your actual control logic
        const dataKey = 'formRequiredKey';  // Example value
        const parentDataKey = 'parentForm';  // Example value

        // Call the handleControlValueChange function
        handleControlValueChange({
            control,
            value: isChecked,
            dataKey,
            parentDataKey,
            state,
            dispatch,
        });

        
    };

    const GetFormList = async (campusId: string) => {
        console.log(campusId, 'Fetching Form List for Selected Campus');
        
        if (campusId) {
            try {
                const response = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getFormList/${campusId}`);
    
                // Ensure response data matches FormItem type
                const formData: FormItem[] = response.data.map((item: any) => ({
                    label: item.widget_name || item.widget_master_name,
                    value: item.widget_inst_id
                }));
    
                setFormList(formData); // ✅ Correctly update state
            } catch (error) {
                console.error('Error fetching form list:', error);
            }
        }
    };



    return (
        <>
        <div className="main flex-1 bg-white">
            <div className="max-1140 mx-auto pt-5">
                <div className="d-flex justify-content-between align-items-center pb-3 ">
                    <h3 className="font-30 font-600 mb-0 block-title position-relative">
                        Manage Widgets <i className="icon-right-arrow font-16 position-relative me-1"></i> <span>{widgetHead}</span>
                    </h3>         
                </div>
            </div>

            <div className="py-5 bg-blue-164F88">
                <div className="max-1140 mx-auto">
                    <div className="row g-3">
                        <div className="col-12">
                            <div className="bg-blue-083461 border-radius-6 px-4 py-3">
                                <div className="row g-3">
                                    <div className="col">
                                    <h3 className="text-white mb-0 font-24">{largeHeading}</h3>
                                    </div>
                                    <div className="col-auto">
                                    <label htmlFor="largeHeading" className="text-white cursor-pointer white-filter"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {code !== 'spotlight' && (
                        <div className="col-12">
                            <div className="bg-blue-083461 border-radius-6 px-4 py-3">
                                <div className="row g-3">
                                    <div className="col">
                                    <p className="text-white">{mediumHeading}</p>
                                    </div>
                                    <div className="col-auto">
                                    <label htmlFor="mediumHeading" className="text-white cursor-pointer white-filter"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='max-1140 py-5 overflow-hidden mx-auto'>
                <div className='row g-4'>
                <div className='col-12'>
                <form className='row g-4' onSubmit={handleSubmit}>
                    <div className="col-sm-6">
                        <label htmlFor="largeHeading" className="form-label m-0 mb-1 font-16 font-500 w-100">Large Heading</label>
                        <input type="text" className="form-control flex-1" id="largeHeading" placeholder="Enter Heading" value={largeHeading} onChange={(e) => setLargeHeading(e.target.value)} />
                        <span style={{ color: "red" }}>{errors.largeHeading}</span>
                    </div>
                    {code === 'alumni-speak' && (
                        <div className="col-sm-6">
                            <label htmlFor="gridLayout" className="form-label m-0 mb-1 font-16 font-500 w-100">
                                Grid Layout
                            </label>
                            <div className="row g-3 align-items-center py-sm-2">
                                <div className="col-auto col-sm-auto col-md-4 col-lg-4">
                                    <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                        
                                        <input
                                            type="radio"
                                            name="gridLayout"
                                            id="row2"
                                            value="2"
                                            className="form-check-input custom-border-84-20 m-0"
                                            checked={gridLayout === '2'}
                                            onChange={handleGridLayoutChange}
                                        />
                                        <label htmlFor="row2" className="form-check-label font-16 font-500 ms-2">
                                            2 in a row
                                        </label>
                                    </div>
                                </div>
                                <div className="col-auto col-sm-auto col-md-4 col-lg-4">
                                    <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                        <input
                                            type="radio"
                                            name="gridLayout"
                                            id="row3"
                                            value="3"
                                            className="form-check-input custom-border-84-20 m-0"
                                            checked={gridLayout === '3'}
                                            onChange={handleGridLayoutChange}
                                        />
                                        <label htmlFor="row3" className="form-check-label font-16 font-500 ms-2">
                                            3 in a row
                                        </label>
                                    </div>
                                </div>
                                <div className="col-auto col-sm-auto col-md-4 col-lg-4">
                                    <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                        <input
                                            type="radio"
                                            name="gridLayout"
                                            id="row4"
                                            value="4"
                                            className="form-check-input custom-border-84-20 m-0"
                                            checked={gridLayout === '4'}
                                            onChange={handleGridLayoutChange}
                                        />
                                        <label htmlFor="row4" className="form-check-label font-16 font-500 ms-2">
                                            4 in a row
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span style={{ color: "red" }}>{errors.gridLayout}</span>
                        </div>
                    )}
                     {code !== 'spotlight' && (
                    <div className="col-sm-12">
                        <label htmlFor="mediumHeading" className="form-label m-0 mb-1 font-16 font-500 w-100">Medium Heading</label>
                        <textarea className='form-control' id="mediumHeading" placeholder="Enter Heading" rows={3} value={mediumHeading} onChange={(e) => setMediumHeading(e.target.value)} />
                        <span style={{ color: "red" }}>{errors.mediumHeading}</span>
                    </div>
                     )}

                    {/* <div className='col-sm-6'>
                        <label htmlFor="mediumHeading" className="form-label m-0 mb-1 font-16 font-500 w-100">Widget Name</label>
                        <input type="text" className='form-control w-150 p-0 height-40' id="widgetName" placeholder="Enter Widget Name" value={widgetName} onChange={(e) => setWidgetName(e.target.value)} />
                     </div> */}

                    <div className='col-sm-6'>
                        <label htmlFor="widgetName" className="form-label m-0 mb-1 font-16 font-500 w-100">Widget Name</label>
                        <input 
                            type="text" 
                            className={`form-control w-150 p-0 height-40 ${errors.widgetName ? 'is-invalid' : ''}`} 
                            id="widgetName" 
                            placeholder="Enter Widget Name" 
                            value={widgetName} 
                            onChange={(e) => setWidgetName(e.target.value)} 
                        />
                        {errors.widgetName && <div className="invalid-feedback">{errors.widgetName}</div>}
                    </div>

                     {/* <div className='col-sm-6'>
                        <label htmlFor="mediumHeading" className="form-label m-0 mb-1 font-16 font-500 w-100">Institute list</label>
                        <select
                            className="form-control flex-1 font-13 font-500"
                             id="instituteList"
                            value={selectedCampus}
                            onChange={handleCampusChange}
                        >
                            <option value="">Select Campus</option>
                            {campuses?.map((campus:any, index:any) => (
                                <option key={index} value={campus.value}>
                                    {campus.label}
                                </option>
                            ))}
                        </select>
                     </div> */}

                    <div className='col-sm-6'>
                        <label htmlFor="instituteList" className="form-label m-0 mb-1 font-16 font-500 w-100">Institute List</label>
                        <select
                            className={`form-control flex-1 font-13 font-500 ${errors.instituteList ? 'is-invalid' : ''}`}
                            id="instituteList"
                            value={selectedCampus}
                            onChange={handleCampusChange}
                        >
                            <option value="">Select Campus</option>
                            {campuses?.map((campus:any, index:any) => (
                                <option key={index} value={campus.value}>
                                    {campus.label}
                                </option>
                            ))}
                        </select>
                        {errors.instituteList && <div className="invalid-feedback">{errors.instituteList}</div>}
                    </div>
                     {/* Primary Color */}
                    <div className="col-sm-6">
                        <label htmlFor="primaryColor" className="form-label font-16 font-500">
                        Primary Color
                        </label>
                        <input
                        type="color"
                        className="form-control"
                        id="primaryColor"
                        value={primaryColor}
                        onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                    </div>

                    {/* Secondary Color */}
                    <div className="col-sm-6">
                        <label htmlFor="secondaryColor" className="form-label font-16 font-500">
                        Secondary Color
                        </label>
                        <input
                        type="color"
                        className="form-control"
                        id="secondaryColor"
                        value={secondaryColor}
                        onChange={(e) => setSecondaryColor(e.target.value)}
                        />
                    </div>

                    <div className='col-sm-6'>
                        <label htmlFor="formRequired" className="form-label m-0 mb-1 font-16 font-500 w-100">
                            Display Enquiry Button
                        </label>
                        <input
                            id="formRequired"
                            data-testid="formRequired"
                            type="checkbox"
                            className="form-check-input"
                            checked={isFormRequired}
                            onChange={handleFormRequiredChange}
                        />
                        {errors.widgetName && <div className="invalid-feedback">{errors.widgetName}</div>}
                    </div>

                    {isFormRequired && (
                        <div className="col-sm-6">
                            <label htmlFor="formList" className="form-label m-0 mb-1 font-16 font-500 w-100">Form List</label>
                            <select
                                className="form-control flex-1 font-13 font-500"
                                id="formList"
                                value={selectedForm}
                                onChange={(e) => setSelectedForm(e.target.value)}
                            >
                                <option value="">Select Form</option>
                                {formList.map((form, index) => (
                                    <option key={index} value={form.value}>{form.label}</option>
                                ))}
                            </select>
                        </div>
                    )}

                    {code === 'topper_zone' && (
                     <div className="col-12">
                        <div className="row g-3">
                          <div className="col-md-auto"><label className="form-label m-0 mb-1 font-16 font-500 ">Menu Tabs</label></div>
                          <div className="col-md">
                            <div className="row g-3">
                              <div className="col-auto">
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="OpenButtonLayout" value={1} checked={OpenButtonLayout == '1'} id="Horizontal" onChange={handleOpenButtonChange} />
                                  <label className="form-check-label">
                                    Horizontal
                                  </label>
                                </div>
                              </div>
                              <div className="col-auto">
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="OpenButtonLayout" value={2} checked={OpenButtonLayout == '2'}  id="Vertical" onChange={handleOpenButtonChange} />
                                  <label className="form-check-label">
                                    Vertical
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                     
                    <input type="hidden" className="form-control flex-1" id="widgetCode" placeholder="Enter Heading" value={widgetCode} />
                    
                    <div className='col-12'>
                        <button type="submit" className="btn btn-green height-45 px-5"><span className="px-3">Save</span></button>
                    </div>
                </form>
                </div>
                

                    <div className='col-sm-12'>
                    {code === 'alumni-speak' ? (
                        id ? <AlumniProfileListExtra/> : null
                    ) : (
                        id ? <AlumniProfileList/> : null
                    )}
                    
                    </div>

                    {id && (
                    <div className="col-sm-12">
                        <div className="action_btns mt-0 max-100per">
                        <button 
                    type="button" 
                    className="nextbtn my-2 my-sm-0 mx-2 px-5" 
                    onClick={handlePreviewClick}
                >
                    Preview
                </button>               
                            {/* <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"><span className="px-3">Save </span></button> */}
                        </div>
                    </div>
                     )}
                </div>

                
            </div>
        </div>
        </>
    );
};

export default GuidingAlumniList;
