import { activateUserSession, isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { getDomainElementsForGivenYearRange } from '../../../../library/Service/domainService';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    console.log('customValidationPassed', customValidationPassed);

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !customValidationPassed) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }

    // Define MoEngage event URL
    const url = sessionState?.userTypeCode === 'SUPER_ADMIN' 
        ? 'moengage-pg-updation' 
        : 'moengage-multiple-pg-updation';

    const updatedJoinedColleges = state.data.postGradCollegeJoined.map((joinedCollege:any) => {
        const updatedJoinedCollege = { ...joinedCollege }; // Make a shallow copy of each college
        if (!updatedJoinedCollege?.didYouReceiveScholarship) {
            updatedJoinedCollege.scholarshipTypeCode = null;
            updatedJoinedCollege.scholarshipAmount = null;
            updatedJoinedCollege.scholarshipCurrencyCode = null;
        }
        if (!updatedJoinedCollege?.didYouGiveEntranceTest) {
            updatedJoinedCollege.entranceTestCode = null;
            updatedJoinedCollege.otherExam = null;
        }
        return updatedJoinedCollege;
    });

    // Send MoEngage event for the updated PG College data
    sendMoEngageEvent(state?.data, url);

    // Save the data
    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            postGradCollege: state?.data['postGradCollege'],
            postGradCollegeJoined: updatedJoinedColleges, 
            postGradCollegeApplied: state.data['profile'].appliedToNoOtherPgColleges === true 
                ? []  
                : state?.data['postGradCollegeApplied'], 
            profile: {
                ...state?.data?.profile,
                appliedToNoOtherUgColleges: state.data.profile.appliedToNoOtherUgColleges, 
            },
        })
    ).then((response) => {
        if (response?.status === 200) {
            // navigateToNextStep(
            //     AlumniProfileEditScreens.POST_GRADUATE_DETAILS,
            //     {
            //         ...state,
            //         data: { ...state.data, postGradCollege: response?.data?.postGradCollege }
            //     },
            //     dispatch,
            //     sessionState,
            //     sessionDispatch
            // );

            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(
                    AlumniProfileEditScreens.POST_GRADUATE_DETAILS, {
                        ...state,
                        data: { ...state.data, postGradCollege: response?.data?.postGradCollege }
                    }, 
                    dispatch, 
                    sessionState,
                    sessionDispatch
                );
            })

            // Optionally, you can activate the session or take other actions
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile/pg`);
            // });
        }
    });
};


export const populateEndYearOnStartYearChange = (props: ControlOnChangeArguments) => {
    if (props.control.id === 'startYear') {
        props.dispatch({
            type: 'SET_DOMAIN',
            payload: {
                key: 'PG_END_YEAR_CODE',
                value: getDomainElementsForGivenYearRange('PG_END_YEAR_CODE', parseInt(props.value), parseInt(props.value) + 3),
            },
        });
    }
};

export const onDidYouReceiveScholarshipChange = (props: ControlOnChangeArguments) => {
    if (!props.value)
        props.dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'postGradCollegeJoined.scholarshipAmount', errorMessages: [] },
        });
    return true;
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.POST_GRADUATE_DETAILS, state, dispatch);
};

//#UAS-1183 dt-3/12/24 by VP for multiple PG Colleges
export const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    let isFormValid = true;
    const errorMessages = [] as any[];
    const joinedColleges = state.data.postGradCollegeJoined;

    joinedColleges.forEach((joinedCollege: any, index: any) => {
        if (isEmpty(joinedCollege?.universityId) && isEmpty(joinedCollege?.otherUniversity)) {
            isFormValid = false;
            setError(`postGradCollegeJoined.${index}.universityId`, [`Please enter "My Postgraduate Degree Was From"`], dispatch);
        }

        if (isEmpty(joinedCollege?.natureOfStudyCode)) {
            isFormValid = false;
            setError(`postGradCollegeJoined.${index}.natureOfStudyCode`, [`Please select "The Nature of Your Degree"`], dispatch);
        }

        if (isEmpty(joinedCollege?.fieldOfStudyCode)) {
            isFormValid = false;
            setError(`postGradCollegeJoined.${index}.fieldOfStudyCode`, [`Please select "Your Field of Study"`], dispatch);
        }

        if (isEmpty(joinedCollege?.startYear)) {
            isFormValid = false;
            setError(`postGradCollegeJoined.${index}.startYear`, [`Please select "Start Year"`], dispatch);
        }

        if (isEmpty(joinedCollege?.endYear)) {
            isFormValid = false;
            setError(`postGradCollegeJoined.${index}.endYear`, [`Please select your "End Year"`], dispatch);
        }

        if (joinedCollege?.didYouReceiveScholarship) {
            if (isEmpty(joinedCollege?.scholarshipTypeCode) || isEmpty(joinedCollege?.scholarshipAmount) || isEmpty(joinedCollege?.scholarshipCurrencyCode)) {
                isFormValid = false;
                setError(`postGradCollegeJoined.${index}.scholarshipAmount`, [`Please fill out Scholarship details`], dispatch);
            }
        }

        if (joinedCollege?.didYouGiveEntranceTest) {
            if (isEmpty(joinedCollege?.entranceTestCode) && isEmpty(joinedCollege?.otherExam)) {
                isFormValid = false;
                setError(`postGradCollegeJoined.${index}.entranceTestCode`, [`Please enter Entrance Test details`], dispatch);
            }
        }
    });

    // Check if at least one applied college is selected
    const appliedCount = state?.data?.postGradCollegeApplied.filter(
        (college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity)
    )?.length;

    if (appliedCount === 0 && state.data['profile'].appliedToNoOtherPgColleges === false) {
        errorMessages.push('Please select at least one college to which you have applied.');
        isFormValid = false;
    }

    // Check for missing offer status
    const notFilledOfferStatusCount = state?.data?.postGradCollegeApplied.filter(
        (college: any) => (!isEmpty(college?.universityId) || !isEmpty(college?.otherUniversity)) && isEmpty(college?.collegeApplicationResultCode)
    )?.length;

    if (notFilledOfferStatusCount > 0 && state.data['profile'].appliedToNoOtherPgColleges === false) {
        errorMessages.push('Please select offer status.');
        isFormValid = false;
    }

    // Dispatch the error messages if any applied colleges have validation errors
    dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'postGradCollegeApplied', errorMessages },
    });

    return isFormValid;
};

const sendMoEngageEvent = async (user:any,url:any) => {    
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/${url}`, {
            userInfo: user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
};