import React, { useState } from 'react';

const LoginWithInstagram: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null); // Optional: Store user info

  // Handle Instagram OAuth login
  const handleLogin = () => {    
    // Construct the OAuth URL
    const facebookAppId = `${process.env.REACT_APP_FACEBOOK_APP_ID}`;
    console.log(facebookAppId, " facebookAppId =============== facebookAppId")
    const redirectUri = `${process.env.REACT_APP_APP_BASE_URL}/socialmedia/instagram-login-success`;
    // const scope = 'public_profile,email,instagram_basic,instagram_manage_insights,business_management'; // Required permissions for posting
    const scope = 'instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights'
    const responseType = 'token'; // We want to get the access token

    const authUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}`;
    // debugger;

    // Redirect the user to the Facebook login page
    window.location.href = authUrl;
  };

  // Check if the user is already redirected back with the access token
  React.useEffect(() => {
    handleLogin();    
  }, []);

  return (
    <div>
      <button onClick={handleLogin}></button>
    </div>
  );
};

export default LoginWithInstagram;