import React, { useState } from 'react';
// import { useImmerReducer } from 'use-immer';
// import { DispatchEvent, State } from '../../library/Core/SmartTypes';
// import smartReducer from '../../library/Core/SmartReducer';

const LoginWithFacebook: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null); // Optional: Store user info
//   const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
//     flags: { isDataLoading: true, showFormErrors: 0 },
//     formValidationErrors: {},
//     internal: {},
//     actions: {
//     },
// } as any);
  // Handle Facebook OAuth login
  const handleLogin = () => {
    // Construct the OAuth URL
    const facebookAppId = `${process.env.REACT_APP_FACEBOOK_APP_ID}`;
    console.log(facebookAppId, " facebookAppId =============== facebookAppId")
    const redirectUri = `${process.env.REACT_APP_APP_BASE_URL}/socialmedia/social-login-success`;
    const scope = 'public_profile,email,pages_manage_posts,pages_read_engagement,pages_show_list,business_management'; // Required permissions for posting
    const responseType = 'token'; // We want to get the access token

    const authUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}`;
    // debugger;

    // Redirect the user to the Facebook login page
    window.location.href = authUrl;
  };

  // Capture the access token from the URL when the user is redirected back
  const captureAccessToken = () => {
    const urlParams = new URLSearchParams(window.location.hash.slice(1));
    const token = urlParams.get('access_token');
    if (token) {
      setAccessToken(token);
      setErrorMessage(null);
      fetchUserInfo(token); // Optionally fetch user info once we have the token
    } else {
      setErrorMessage('Login failed or was cancelled.');
    }
  };

  // Fetch user info using the access token
  const fetchUserInfo = (token: string) => {
    fetch(`https://graph.facebook.com/me?access_token=${token}&fields=id,name,email`)
      .then((response) => response.json())
      .then((data) => {
        if (data && !data.error) {
          setUser(data);
        } else {
          setErrorMessage('Failed to fetch user information.');
        }
      })
      .catch((error) => {
        setErrorMessage('Error fetching user information: ' + error.message);
      });
  };

  // Check if the user is already redirected back with the access token
  React.useEffect(() => {
    handleLogin();
    if (window.location.hash) {
      captureAccessToken();
    }
  }, []);

  return (
    <div>
      {/* <h6>Connect Facebook Account</h6>
      {accessToken ? (
        <div>
          <p>Successfully connected to Facebook!</p>
          {user && (
            <div>
              <p>User Info:</p>
              <p>Name: {user.name}</p>
              <p>Email: {user.email}</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button onClick={handleLogin}>Login with Facebook</button>
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      )} */}
          <button onClick={handleLogin}></button>

    </div>
  );
};

export default LoginWithFacebook;