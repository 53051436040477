// import { convertDomainArrayToMap } from '../../../library/Core/SmartFunctions';
// import logger from '../../../library/Core/logger';
// import { axiosClient } from '../../../library/Service/axiosClient';
// import DomainDataService from '../../../services/domain-data.service';

import logger from "../../library/Core/logger";
import { convertDomainArrayToMap } from "../../library/Core/SmartFunctions";
import { axiosClient } from "../../library/Service/axiosClient";
import DomainDataService from "../../services/domain-data.service";

class SocialPostListService {
    static filters = {
        userTypeCode: [],
        year: [],
        tags: [],
    };

    static pageLoader(params: any) {
        const state: any = {};
        const { id, pageName } = params;

        return Promise.all([SocialPostListService.get(), DomainDataService.get()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.data = {
                    all: values[0]?.data?.data || [],
                    filtered: values[0]?.data?.data?.filter((rec: any) => rec.status?.toLowerCase() === 'published') || []
                };
                state.internal = {
                    selectedTab: 'published'
                }
                state.domain = values[1].data instanceof Map ? values[1].data : convertDomainArrayToMap(values[1].data);
                state.routeInfo = { id, pageName };
                // storeDomainData(state.domain);
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static async get(type = 'ALL', userId = '') {
        let apiUrl = `${process.env.REACT_APP_COMMON_API}/social/get-socialmedia-posts`
       
        return await axiosClient().get(apiUrl);
    }
}

export default SocialPostListService;
