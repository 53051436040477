import { convertDomainArrayToMap, getDomainData, getDomainValueForCode, isEmpty } from '../../../library/Core/SmartFunctions';
import { ObjectWithKeys, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import GlobalStateService from '../../../services/GlobalState.service';
const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};

class EventsService {
    static domainMap: any;

    async initialize() {
        const domain = await EventsService.getDomain();
        EventsService.domainMap = await domain instanceof Map ? domain : convertDomainArrayToMap(domain);
    }

    static pageLoader = async (params: any) => {
        const state: any = {};
        const { searchBy, pageName } = params;

        const filters = EventsService.getDefaultFilters();
        const filterDomain = await EventsService.getDefaultFilterDomain(searchBy);
        const loginUserData = await EventsService.getUserInfo();
        state.formConfig = {};
        state.data = {
            actionType: 'LOAD_PAGE',
            events: [],
            counts: {},
            searchBy,
            prevSearchBy: searchBy,
            filters,
            filterDomain,
        };
        state.internal = {
            userData: loginUserData
        }

        state.routeInfo = { searchBy, pageName };
        return Promise.resolve(state);
    };

    static async getFilterDomain(searchBy: string, filterName: any) {
        const config = { headers: { 'Referrer-Policy': 'no-referrer' } };
        let response = await axiosClient().post(
            `${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/get-filters`,
            this.getParamsForFilter(searchBy, filterName),
            config
        );

        if (isEmpty(response?.data)) {
            return [];
        }

        if (isEmpty(EventsService.domainMap)) {
            const domain = await EventsService.getDomain();
            EventsService.domainMap = await domain instanceof Map ? domain : convertDomainArrayToMap(domain);
        }

        response?.data?.forEach((item: any) => {
            let domainCategoryCode = this.getDomainCategoryCode(filterName);

            if (isEmpty(domainCategoryCode)) {
                return false;
            }

            item.displayText = getDomainValueForCode(item?.code, domainCategoryCode, { domain: EventsService.domainMap } as State);
        });

        return response;
    }

    static async getUserInfo() {
        let response = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/user-info`);
        if (!isEmpty(response?.data)) {
            return response.data;
        }

        return [];
    }

    static getDomainCategoryCode(filterName: any) {
        let domainCategoryCode = '';

        switch (filterName) {
            case 'type':
                // domainCategoryCode = 'EVENT_TYPE_INSTITUTE_ADMIN';
                domainCategoryCode = 'EVENT_TYPE_ALL';
                break;

            case 'theme':
                domainCategoryCode = 'EVENT_THEME';
                break;

            case 'presenter':
                domainCategoryCode = 'EVENT_PRESENTER';
                break;

            case 'skill':
                domainCategoryCode = 'EVENT_SKILLS';
                break;

            case 'university':
                domainCategoryCode = 'INSTITUTE_DOMAIN';
                break;

            case 'test':
                domainCategoryCode = 'EVENT_TEST';
                break;

            case 'country':
                domainCategoryCode = 'COUNTRY_CODE';
                break;

            case 'industry':
                domainCategoryCode = 'INDUSTRY_CODE';
                break;
        }

        return domainCategoryCode;
    }
    static getAdditionalInfo(searchBy: string) {
        return {
            login_ref_id: sessionStorage.getItem('user-login_ref_id'),
            school_ref_id: '',
            upcoming_past: searchBy === 'upcoming' ? '1' : '2',
            req_from: 'alumni',
        };
    }

    static getParamsForFilter(searchBy: string, filterName: string) {
        const filterData = EventsService.filterConfig[filterName];
        const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};

        return {
            req_from: EventsService.getRequestFrom(),
            // institute_id: localUserData?.primaryInstitute,
            // login_ref_id: '', // '448220', // sessionStorage.getItem('user-login_ref_id'),
            searchBy: searchBy, // upcoming/past/your-drafts/created-by-you
            filtername: filterData?.filterName,
            fieldType: filterData?.fieldType,
            [localUserData?.userTypeCode?.toLowerCase()]: 1,
            programLevel: localUserData?.programLevel,
            passOutYear: localUserData?.passOutYear,
        };
    }

    static getRequestFrom = () => {
        let requestFrom = '';
        switch (sessionStorage.getItem('user-type-code')) {
            case 'ALUMNI':
                requestFrom = 'alumni';
                break;
            case 'STUDENT':
                requestFrom = 'student';
                break;
            case 'SCHOOL':
            case 'SCHOOL_ADMIN':
                requestFrom = 'schooldashboard';
                break;
            default:
                requestFrom = 'schooldashboard';
        }
        return requestFrom;
    };

    static getDefaultFilters = () => {
        return {
            eve_types: [],
            eve_presenter: [],
            eve_themes: [],
            eve_test: [],
            eve_skill: [],
            eve_country: [],
            eve_university: [],
            eve_career: [],
            eve_department: [],
            eve_pdt: [],
            eve_inds: [],
        };
    };

    static async getDefaultFilterDomain(searchBy: string) {
        const domains = [
            'type',
            'theme',
            'presenter',
            'skill',
            'university',
            'test',
            'country',
            // 'career',
            // 'department',
            // 'product',
            'industry',
        ];

        // const savedFilterDomains = sessionStorage.getItem(`${searchBy}.filterDomains`);

        //to : need to check if array does not have any element
        // if (!isEmpty(savedFilterDomains)) {
        //     return JSON.parse(savedFilterDomains as any);
        // }

        const values: any = await Promise.all(domains.map((domain) => EventsService.getFilterDomain(searchBy, domain)));
        const filterDomains = domains.reduce((acc, domain, index) => {
            // acc[domain] = values[index].data?.response?.result;
            acc[domain] = values[index]?.data;
            return acc;
        }, {} as Record<string, any>);

        // sessionStorage.setItem(`${searchBy}.filterDomains`, JSON.stringify(filterDomains));

        return filterDomains;
    }

    static filterConfig: ObjectWithKeys = {
        type: {
            id: 'type',
            filterName: 'me.event_type',
            label: 'Event Type',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_types',
            fieldType: 'int',
        },
        theme: {
            id: 'theme',
            filterName: 'ea.theme_type',
            label: 'Theme / Topic',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_themes',
            fieldType: 'json_array',
        },
        presenter: {
            id: 'presenter',
            filterName: 'me.presenter_category',
            label: 'Presenter Category',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_presenter',
            fieldType: 'int',
        },
        skill: {
            id: 'skill',
            filterName: 'ea.skill_type',
            label: 'Select Skill',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_skill',
            fieldType: 'json_array',
        },
        university: {
            id: 'university',
            filterName: 'me.related_institute',
            label: 'Select University',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_university',
            fieldType: 'json_array',
        },
        test: {
            id: 'test',
            filterName: 'ea.test_type',
            label: 'Select Test',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_test',
            fieldType: 'json_array',
        },
        country: {
            id: 'country',
            filterName: 'ea.countries',
            label: 'Select Country',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_country',
            fieldType: 'json_array',
        },
        // career: {
        //     id: 'career',
        //     filterName: 'get_tag_career',
        //     label: 'Select Career',
        //     code: 'career_id',
        //     displayText: 'career_name',
        //     searchParam: 'eve_career',
        // },
        // department: {
        //     id: 'department',
        //     filterName: 'get_tag_department',
        //     label: 'Select Department',
        //     code: 'department_id',
        //     displayText: 'department_name',
        //     searchParam: 'eve_department',
        // },
        industry: {
            id: 'industry',
            filterName: 'ea.industry_type',
            label: 'Select Industry',
            code: 'code',
            displayText: 'displayText',
            searchParam: 'eve_inds',
            fieldType: 'json_array',
        },
        // product: {
        //     id: 'product',
        //     filterName: 'get_tag_product',
        //     label: 'Select Product',
        //     code: 'product_id',
        //     displayText: 'product_name',
        //     searchParam: 'eve_pdt',
        // },
    };

    static async getDomain() {
        try {
            const domainData = await getDomainData();
            // Logic to derive filter domains based on `searchBy` and `domainData`
            return domainData; // Adjust this based on specific requirements
        } catch (error) {
            console.error('Error fetching default filter domain:', error);
            return {};
        }
    }
}

export default EventsService;

// async function initializeApp() {
//     const eventService = new EventsService();
//     await eventService.initialize();
// }

// initializeApp().catch((error) => console.error('Error during initialization:', error));