import { useContext, useEffect, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import FormBuilder from '../../../library/Builders/FormBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getFuzzySearchCriteria, isEmpty } from '../../../library/Core/SmartFunctions';
import { FormSection } from '../../../library/Core/SmartTypes';
import FiltersApplied from '../../../library/SimpleControls/FiltersApplied';
import InternalAdminReportService from './InternalAdminReportService';

export const SearchCriteria = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const searchFormConfig = state.formConfig?.sectionRepository[0] as FormSection;
    const [isExpanded, setIsExpanded] = useState(true);
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);  // Flag for initial load

    // useEffect(() => {
    //     InternalAdminReportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
    //         setStateValue('alumniList', response.data);
    //         fetchAlumniCount(state);
    //     });
    // }, [state.data.searchCriteria]);

    useEffect(() => {
        if (isInitialLoad) {
            fetchAlumniCount(state);
            setIsInitialLoad(false);
            return; 
        }

        InternalAdminReportService.search(state.data.searchCriteria, state.routeInfo.pageName)
            .then((response: any) => {
                setStateValue('alumniList', response.data);
                fetchAlumniCount(state);
            });
    }, [state.data.searchCriteria]);

    // useEffect(() => {
    //     if (isEmpty(fuzzySearchText))
    //         InternalAdminReportService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
    //             setStateValue('alumniList', response.data);
    //             const fetchAlumniCount = async () => {
    //                 try {
    //                     const alumniCountData = await InternalAdminReportService.searchCount(getFuzzySearchCriteria(fuzzySearchText), state.routeInfo.pageName);
                                        
    //                     dispatch({
    //                         type: 'SET_INTERNAL_STATE',
    //                         payload: { key:"alumniCount", value:alumniCountData?.data },
    //                     });
    //                 } catch (error) {
    //                     console.error("Error fetching alumni count:", error);
    //                 }
    //             };
            
    //             fetchAlumniCount();
    //         });
    // }, [fuzzySearchText]);

    const handleFuzzySearch = (event: any) => {
        event.preventDefault();
        if (isEmpty(fuzzySearchText)) return;
        
        const searchCriteria = {...state.data.searchCriteria,...getFuzzySearchCriteria(fuzzySearchText)}
        setStateValue('searchCriteria',state.routeInfo.pageName !== 'internal-admin-all-alumni-report' ? getFuzzySearchCriteria(fuzzySearchText) : searchCriteria);

        //     InternalAdminReportService.search(searchCriteria, state.routeInfo.pageName).then((response: any) => {
        //         setStateValue('alumniList', response.data);
        //         const fetchAlumniCount = async () => {
        //             try {
        //                 const alumniCountData = await InternalAdminReportService.searchCount(searchCriteria, state.routeInfo.pageName);
                                        
        //                 dispatch({
        //                     type: 'SET_INTERNAL_STATE',
        //                     payload: { key:"alumniCount", value:alumniCountData?.data },
        //                 });
        //             } catch (error) {
        //                 console.error("Error fetching alumni count:", error);
        //             }
        //         };
            
        //         fetchAlumniCount();
        //     });
    };

    const handleClearAll = () => {
        const defaultCriteria = InternalAdminReportService.getDefaultSearchCriteria(state.routeInfo.pageName);

        defaultCriteria.lastCallStatusCode = "";
        defaultCriteria.accountStatusCode = "";
        defaultCriteria.profileCompletionStatusCode = "";
        defaultCriteria.isPrestigiousCollege = "";
        defaultCriteria.isPrestigiousUniversity="";
        defaultCriteria.schoolClientStatusCode = "";
        defaultCriteria.testProfileStatusCode = "";
        defaultCriteria.isValidCollegeCode = "";
        defaultCriteria.isValidCourseCode = "";
        defaultCriteria.schoolGroupId = "";
        
        defaultCriteria.pairingWithSimilarAlumni = "";
        defaultCriteria.networkForSocialCausesVolunteer = "";
        defaultCriteria.networkForBusinessIdeasAndPartnerships = "";
        defaultCriteria.networkSamePgCourseAlumni = "";
        defaultCriteria.jobAndInternships = "";
        defaultCriteria.eventsInterestedInUpskilling = "";
        defaultCriteria.eventsInterestedInEntrepreneurship = "";
        defaultCriteria.hasHigherStudies = "";

        defaultCriteria.collegeTypeCodeJson = "";
        defaultCriteria.examTypeCodeJson = "";
        defaultCriteria.presentEducationStatusCode = "";
        defaultCriteria.ispeerReviewed="";

        document.querySelectorAll('.css-1dimb5e-singleValue').forEach(element => {
            element.textContent = ''; 
        }); //universityId text not removed after clear

        if(state.routeInfo.pageName === "internal-admin-user-entered-college") defaultCriteria.isNonStandardCollege = 1;
        if(state.routeInfo.pageName === "internal-admin-user-entered-exam") defaultCriteria.isNonStandardExam = 1;

        
        setStateValue('searchCriteria', defaultCriteria);
    };

    const fetchAlumniCount = async (state:any) => {
        try {
            const alumniCountData = await InternalAdminReportService.searchCount(state.data.searchCriteria, state.routeInfo.pageName);
            await new Promise(resolve => setTimeout(resolve, 200));
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key:"alumniCount", value:alumniCountData?.data },
            });
        } catch (error) {
            console.error("Error fetching alumni count:", error);
        }
    }

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                    <button className="btn-grpfiter me-3" onClick={() => setIsExpanded(!isExpanded)}>
                        <i className="icon-filter font-13 me-2"></i>
                        Filters
                    </button>
                    <div className="search alumniSearch m-0 open position-static flex-1 mb-2">
                        <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                            <input
                                value={fuzzySearchText}
                                placeholder="Search by Alumni Name, Email, User ID"
                                aria-label="Search"
                                className="form-control flex-1 font-13 font-500 text-truncate"
                                onChange={(event) => setFuzzySearchText(event?.target.value)}
                            />
                            <button className="btn btn-search icon icon-search" onClick={handleFuzzySearch}></button>
                        </div>
                        <a href="" className="btn-search-toggle icon-search"></a>
                    </div>
                    {isExpanded && (
                        <div className="col-12 fitergroup p-3 bg-body-secondary">
                            <FormBuilder key="searchCriteria" section={searchFormConfig} dataKey="searchCriteria" />
                        </div>
                    )}
                </div>
            </div>

            <div className="filterresult">
                <FiltersApplied dataKey="searchCriteria" onClearAll={handleClearAll} />
            </div>
        </>
    );
};
