import { useContext, useState, useEffect } from 'react';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import ProfileMakerService from './ProfileMakerService';
import { Tooltip } from 'bootstrap';
import { isEmpty, setError } from '../../../../../../library/Core/SmartFunctions';

const WorkProfile = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const markers = state.data.marker || [];
    const markerType = 'workProfile';
    const { saveWorkRelatedMarker, fetchUserProfileMarkers,fetchUserSyncDetails,handleOpenLogsModal } = ProfileMakerService();
    type WorkProfileProperties = {
        is_leadership_role_in_large_company: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
        work_experience: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
        is_leadership_role_in_a_listed_company: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
        is_leadership_role_in_sme_company: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
        is_growing_startup_founder: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
        is_mid_senior_role_in_fortune_company: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
        director_or_board_member: { label: string; showInputBox: boolean; toolTip: string; showCommentBox: boolean };
    };

    const workProfileProperties: WorkProfileProperties = {
        is_leadership_role_in_large_company: {
            label: 'Leadership Role in Large Company',
            showInputBox: false,
            toolTip: 'Alumnus is in "Leadership" roles like “CEO, COO, SVP, VP, National Head” etc in a company with 1000+ employees',
            showCommentBox: true,
        },
        work_experience: {
            label: 'Work Experience > 15 Years',
            showInputBox: true,
            toolTip: 'Alumnus has overall work experience of more than 15 years',
            showCommentBox: true,
        },
        is_leadership_role_in_a_listed_company: {
            label: 'Leadership Role in a Listed Company',
            showInputBox: false,
            toolTip:
                'Alumnus is in Leadership roles like CEO, COO, SVP, VP, Director etc in a company that is listed on Stock Exchanges like BSE, NSE, NYSE, LSE etc',
            showCommentBox: true,
        },
        is_leadership_role_in_sme_company: {
            label: 'Leadership Role in SME Company',
            showInputBox: false,
            toolTip:
                'Alumnus is in "Leadership" roles like “CEO, COO, SVP, VP, National Head” etc in a company with 200-1000 employees',
            showCommentBox: true,
        },
        is_growing_startup_founder: {
            label: 'Growing Start-up Founder',
            showInputBox: false,
            toolTip: 'Alumnus is a “Founder/ Co-Founder” or a unicorn startup or a startup with 250+ employees',
            showCommentBox: true,
        },
        is_mid_senior_role_in_fortune_company: {
            label: 'Mid-Senior Role in Fortune 500 Company',
            showInputBox: false,
            toolTip: 'Alumnus is in "Mid-Senior" roles like “Director, Regional Manager, Senior Manager” etc in a Fortune 500 company',
            showCommentBox: true,
        },
        director_or_board_member: {
            label: 'Director OR Board Member of Company',
            showInputBox: false,
            toolTip: 'Alumnus is a “Director” or “Board Member” of any company as per MCA India, or similar international entities',
            showCommentBox: true,
        },
    };

    const [isWorkModalOpen, setWorkProfileModal] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
    const [selectedMarker, setSelectedMarker] = useState<any>(null);
    const [formValue, setFormValue] = useState('');
    const [formRemark, setFormRemark] = useState('');
    const [markerEnabled, setMarkerEnabled] = useState('0');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userSynacdata = await fetchUserSyncDetails(state?.internal?.model?.id);
                
                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: {
                        dataKey: 'syncData',
                        value: userSynacdata, 
                    },
                });
            } catch (error) {
                console.error('Error fetching marker sync data:', error);
            }
        };

        fetchData();
    }, []);

    const openWorkProfileModal = (key: string) => {
        setSelectedProperty(key);
        // const markerExists = Array.isArray(markers?.workProfile) && markers.workProfile.some((m: any) => m.markerField === key);
        const marker = (Array.isArray(markers?.workProfile) && markers?.workProfile?.find((m: any) => m.markerField === key)) || {
            markerField: key,
            value: '',
            remark: '',
        };
        setSelectedMarker(marker);
        setMarkerEnabled(marker.markerEnabled || '0');
        setFormValue(marker.value || '');
        setFormRemark(marker.remark || '');
        setWorkProfileModal(true);
    };

    const handleSave = async () => {
        if (selectedProperty === 'work_experience') {
            const numericValue = parseFloat(formValue);
            console.log("numericValue",numericValue)
            console.log("markerEnabled",markerEnabled)
            if (markerEnabled == '1' && (isNaN(numericValue) || numericValue < 15)) {
                alert('Please enter a value greater than or equal to 15 for Work Experience.');
                return; 
            }
            if (markerEnabled == '0' && numericValue >= 15) {
                alert('Value for Work Experience should not be greater than or equal to 15 if the checkbox is not checked.');
                return;
            }
        }

        const propertiesToValidate = [
            'work_experience',
            'is_leadership_role_in_large_company',
            'is_leadership_role_in_sme_company',
            'is_leadership_role_in_a_listed_company',
            'is_growing_startup_founder',
            'is_mid_senior_role_in_fortune_company',
        ];
    
        if (selectedProperty && propertiesToValidate.includes(selectedProperty)) {
            const syncData = state?.data?.syncData?.[0] || {};
                const syncDataValue = String(syncData[selectedMarker.markerField]);
                const markerEnabledValue = String(markerEnabled);
                if (syncDataValue !== (selectedMarker.markerField == 'work_experience' ? selectedMarker.value : markerEnabledValue)) {
                    if (isEmpty(formRemark)) {
                        const fieldKey = selectedMarker.markerField as keyof WorkProfileProperties;
                        alert(`Please enter a comment for "${workProfileProperties[fieldKey].label}" because the value has changed.`);
                        return;
                    }
                }
        }      

        const updatedMarker = {
            ...selectedMarker,
            value: formValue,
            // remark: (markerEnabled=="1") ? formRemark : '',
            remark: formRemark,
            markerEnabled: markerEnabled,
            markerType: selectedMarker.markerType || markerType,
        };
        const response = await saveWorkRelatedMarker(updatedMarker, state.internal.model.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        setWorkProfileModal(false);
    };

    const keys = Object.keys(workProfileProperties) as (keyof WorkProfileProperties)[];

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, [!isWorkModalOpen]);

    const getRemarkForKey = (key: string) => {
        const workProfileMarkers = Array.isArray(markers?.workProfile) ? markers.workProfile : [];
        const marker = workProfileMarkers.find((m: any) => m.markerField === key);
        return marker?.remark ? marker.remark : null;
    };

    return (
        <>
            <div className="tab-pane fade active show" id="v-pills-work-profile" role="tabpanel" aria-labelledby="v-pills-work-profile-tab">
                <div className="max-470 mx-auto">
                    <div className="row g-3">
                        {keys.map((key) => {
                            const markerExists =
                                Array.isArray(markers?.workProfile) &&
                                markers.workProfile.some((m: any) => {
                                    if (m.markerField === key) {
                                    if (key === 'work_experience') {
                                        return m.markerField === key && parseFloat(m.value) >= 15;
                                    }
                                    return m.markerEnabled === 1;
                                    }
                                    return false;
                                });
                            const remark = getRemarkForKey(key);
                            return (
                                <div className="col-12" key={key}>
                                    <div className="row g-3 align-items-center">
                                        <div className="col">
                                            <div
                                                className={`listed-border ${markerExists ? '' : 'disabled'}`}
                                                onClick={() => openWorkProfileModal(key)}>
                                                <i className="tick"></i>
                                                <p>{workProfileProperties[key].label}</p>
                                                {markerExists && remark && (
                                                    <span
                                                        className="note-wrap"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-html="true"
                                                        title={remark }>
                                                        <i className="icon-note"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i
                                                className="icon-info"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title={workProfileProperties[key].toolTip}
                                                aria-label={workProfileProperties[key].toolTip}></i>
                                        </div>
                                    </div>
                                </div>

                         

                            );

                         
                        })}
                        <div className="col-12 pt-3">
                            <button className="btn btn-dark-blue w-auto height-40 px-4 d-flex align-items-center mx-auto" onClick={() => handleOpenLogsModal()}>
                            <span className="text-white">View Update Logs</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`modal fade show vh-100 ${isWorkModalOpen ? 'show' : ''}`}
                id="AddRemark"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                style={{ display: isWorkModalOpen ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)', paddingLeft: '0px' }}>
                <div className="modal-dialog modal-dialog-centered max-650 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body p-3 p-sm-5">
                            <button
                                type="button"
                                className="btn-close btn-close-small m-0"
                                onClick={() => setWorkProfileModal(false)}
                                aria-label="Close"></button>
                            <h5 className="modal-title text-center w-100 font-22 font-600 mb-4">Add Remark</h5>
                            <form>
                                <div className="row g-4">
                                    {selectedProperty && (
                                        <>
                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={markerEnabled == '1'}
                                                        onChange={(e) => setMarkerEnabled(e.target.checked ? '1' : '0')}
                                                        id={selectedProperty}
                                                    />
                                                    <label className="form-check-label" htmlFor={selectedProperty}>
                                                        {workProfileProperties[selectedProperty as keyof WorkProfileProperties].label}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="row g-3">
                                                    {workProfileProperties[selectedProperty as keyof WorkProfileProperties]
                                                        .showInputBox && (
                                                        <div className="col-auto">
                                                            <input
                                                                type="number" 
                                                                value={formValue}
                                                                onChange={(e) => setFormValue(e.target.value)}
                                                                className="form-control w-90 text-center"
                                                            />
                                                        </div>
                                                    )}

                                                    {(workProfileProperties[selectedProperty as keyof WorkProfileProperties]
                                                        .showCommentBox ||
                                                        markerEnabled == '1') && (
                                                        <div className="col">
                                                            <input
                                                                type="text"
                                                                value={formRemark}
                                                                onChange={(e) => setFormRemark(e.target.value)}
                                                                placeholder="Enter remarks"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="col-12">
                                        <button type="button" className="btn btn-green mx-auto" onClick={handleSave}>
                                            <span className="px-5">Save</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkProfile;
