import React, { useContext, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { useLoaderData, useParams  } from 'react-router-dom';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import SessionContext from '../../library/Core/SessionContext';
import { GlobalContext } from '../../library/Core/GlobalContext';
import ManageWidgetsListService from './ManageWidgetsListService';
import { getDomainValueForCode, getFlexColDomainValueForCode } from '../../library/Core/SmartFunctions';
import { addUserSpecificDomains } from '../../library/Service/domainService';

const ManageWidgetsList = () => {
    const { globalState } = useContext(GlobalContext);
    const { getCampuses } = useManagementDashboard();
    const { sessionState } = useContext(SessionContext);
    // State for selected campus and filtered data
    const [selectedCampus, setSelectedCampus] = useState('');
    const [filteredData, setFilteredData] = useState([]);

   const campuses =(sessionState?.userTypeCode=='INSTITUTE_ADMIN') ?  getCampuses() : globalState.domain.get('INSTITUTE_DOMAIN')?.map((data) => {
        return {label:data.value, value:data.code};
    });

    //console.log(campuses, 'campuses list data')
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { data } = useLoaderData() as { data: State['actions'] };

    //const flexCol = getFlexColDomainValueForCode(alumniData?.instituteId, 'INSTITUTE_DOMAIN', state);
    const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
    useEffect(() => {
        
        dispatch({
            type: 'DATA_INIT',
            payload: { data, domain},
        });

    }, []);

    /**
     * This function determines the configuration module slug based on the provided widget code.
     * It is used to generate the correct URL for configuring specific widgets.
     *
     * @param widgetCode - The unique identifier of the widget.
     * @returns The configuration module slug corresponding to the given widget code.
     *          If the widget code matches any of the specified cases, the function returns the corresponding configuration module slug.
     *          Otherwise, it defaults to 'configuration'.
     */
    const getConfigurationModuleSlug = (widgetCode: any) => {
        let configurationModule = 'configuration';

        switch (widgetCode) {
            case 'top_careers':
            case 'top_study_country':
            case 'top_companies':
            case 'top_colleges':
            case 'directory_stats':
                configurationModule = 'configure';
                break;

            case 'guiding_alumni_mentors':
            case 'notable_alumni_mentors':
            case 'alumni-speak':
            case 'eclectic_career_alumni':
            case 'entrepreneur_alumni':
            case 'topper_zone':
            case 'spotlight':
                configurationModule = 'configurelist';
                break;
        }

        return configurationModule;
    }

    const [isActive, setIsActive]: any = useState({});
    const [isHidden, setIsHidden] = useState(false);

    const handleClick = (id: any) => {
        setIsActive((prevState: any) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    // Handle campus selection change
    // const handleCampusChange = (e:any) => {
    //     const selectedValue = e.target.value;
    //     setSelectedCampus(selectedValue);

    //     // Filter data based on selected campus
    //     const filtered = campuses.filter((item:any) => item.campus === selectedValue);
    //     setFilteredData(filtered);

    //     ManageWidgetsListService.search(state.data.searchCriteria).then((response: any) => {
    //         setState((prevState) => ({
    //             ...prevState,
    //             data: {
    //                 ...prevState.data,
    //                 wedgitList: response.data, // Update wedgitList in the state
    //             },
    //         }));
    //     });
    // };
    const [widgetState, setWidgetState] = useState<any[]>([]);

    useEffect(() => {
        console.log('Current state:', state.data);
        setWidgetState(state.data?.wedgitList)
    }, [state]);

    const handleCampusChange = (e: any) => {
        const selectedValue = e.target.value; // Get the selected campus
        setSelectedCampus(selectedValue); // Update selected campus state
    
        // Filter data based on selected campus (if necessary for UI)
        const filtered = campuses.filter((item: any) => item.campus === selectedValue);
        setFilteredData(filtered);
    
        // Update searchCriteria with the selected campus
        const updatedSearchCriteria = {
            ...state.data.searchCriteria,
            instituteId: selectedValue, // Add or update the campus in the search criteria
        };
    
        // Call the service with the updated searchCriteria
        ManageWidgetsListService.search(updatedSearchCriteria).then((response: any) => {

            console.log(response.data, 'search response data value')
            setWidgetState(response.data)
            
        });
    };

    const instituteValue = (instituteId: any) => {
        let flexCol;
        if (instituteId) {
            flexCol = getDomainValueForCode(instituteId, 'INSTITUTE_DOMAIN', state);
        } else {
            flexCol = '';
        }
        console.log(flexCol, 'flexCol data')
        return flexCol; 
    };


    return (
        <>
            <div className="main flex-1 bg-white">
                <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
                    <div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
                        <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title position-relative">
                            Manage Widgets For Alumni
                        </h3>
                    </div>
                    
                    {state.flags.isDataLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <div className="manage-widgets-wrap">

                            <div className="search alumniSearch m-0 open position-static flex-1 mb-3">
                                <form className="form-inline d-flex flex-wrap justify-content-between d-block">
                                    {/* Campus Selection Dropdown */}
                                    <select
                                        className="form-control flex-1 font-13 font-500"
                                        value={selectedCampus}
                                        onChange={handleCampusChange}
                                    >
                                        <option value="">Select Campus</option>
                                        {campuses?.map((campus:any, index:any) => (
                                            <option key={index} value={campus.value}>
                                                {campus.label}
                                            </option>
                                        ))}
                                    </select>
                                    <button className="btn btn-search icon icon-search" type="submit"></button>
                                </form>
                                <a href="javascript:;" className="btn-search-toggle icon-search"></a>
                            </div>
                            <ul>
                            {Array.isArray(widgetState) && widgetState.map((widget: any) => (
                                    <li key={widget.id} className='p-0'>
                                        <div className="px-3 py-2">
                                            <div className="row g-2 g-md-3 py-1">
                                                <div className="col-md-auto">
                                                    <div className="d-flex h-100 align-items-center justify-content-center"> 
                                                        <i 
                                                        className="icon-down-arrow cursor-pointer" 
                                                        // data-bs-toggle="collapse" 
                                                        // data-bs-target={`#${widget.name.replace(/\s+/g, '')}`} 
                                                        aria-expanded={`${isActive[widget.name.replace(/\s+/g, '')] ? 'false' : 'true'}`}
                                                        // aria-controls={widget.name.replace(/\s+/g, '')} 
                                                        onClick={() => handleClick(widget.name.replace(/\s+/g, ''))}></i>
                                                    </div>
                                                </div>  
                                                <div className="col-md">
                                                    <div className="form-check form-check-tick">
                                                        {/* <input className="form-check-input d-none" type="checkbox" value="" id="Hero" /> */}
                                                        <label className="form-check-label font-18 font-500 flex-1">
                                                            {widget.name}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-auto">
                                                    <div className="d-flex align-items-center">
                                                        {widget.widgetInstId ? (
                                                            <>
                                                                <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}`} className="btn btn-configure me-2">
                                                                    <span>CONFIGURE</span>
                                                                </a>
                                                                {/* <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}/${widget.widgetInstId}`} className="btn btn-widgets-edit me-2">
                                                                    <span>Edit</span>
                                                                </a>
                                                                <a href={`${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${widget.widgetInstId}`} target='_blank' className="btn btn-priview">
                                                                    <span>Preview</span> 
                                                                </a> */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}`} className="btn btn-configure">
                                                                    <span>CONFIGURE</span>
                                                                </a>
                                                            </>
                                                        )}
                                                    
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                     
                                        <div className={`collapse ${isActive[widget.name.replace(/\s+/g, '')] ? '' : 'show'}`} >
                                            <div className="p-3 bg-light">
                                                <div className="inner-widget-list-wrap">
                                                
                                                {widget?.sub_list.map((sublist: any) => (
                                                    <div className="widget-list py-2">
                                                    <div className="row g-2 g-md-3 py-1" >                   
                                                    <div className="col-md">
                                                            <div className="form-check form-check-tick h-100 d-flex align-items-center ">
                                                            
                                                                <label className="form-check-label font-18 font-500 flex-1">
                                                                {sublist.widget_name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="form-check form-check-tick h-100 d-flex align-items-center ">
                                                            
                                                                <label className="form-check-label font-18 font-500 flex-1">
                                                                {/* {sublist.institute_id} */}
                                                                {instituteValue(sublist.institute_id)}
                                                                
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-auto">
                                                        <div className="d-flex align-items-center">
                                                            <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}/${sublist.widget_inst_id}`} className="btn btn-widgets-edit me-2">
                                                                <span>Edit</span>
                                                            </a>
                                                            <a href={`${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${sublist.widget_inst_id}`} target='_blank' className="btn btn-priview">
                                                                <span>Preview</span>
                                                            </a>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ManageWidgetsList;