import React from 'react';

const ImageTemplate: React.FC = () => {
    return (
        <div className="main flex-1">
            <section className="notificationSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius h-100 p-0">
                                <div className="m-w-90 d-flex flex-wrap justify-content-between align-items-center mb-1 px-4 pt-4 pb-0">
                                    <h3 className="font-35 font-600 mb-0 block-title" data-aos="fade-left" data-aos-delay="600">
                                        Create New Image Template
                                    </h3>
                                </div>
                                <div className="p-4 JobInternshipListing">
                                    <div className="row g-4">
                                        <div className="col-md-auto">
                                            <div className="ji_filtersec">
                                                <div className="mobileFilter mobileonly text-end">
                                                    <button
                                                        className="btn-filter btn-unscroll font-18 font-500"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#filter_collapse"
                                                        aria-expanded="true"
                                                        aria-controls="filter_collapse">
                                                        {/* <img src="../images/filter_icon.jpg" alt="Filter Icon" />Filters */}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="polarcontsec" id="myTabContent">
                                                <div className="row g-3 g-sm-3 g-md-4">
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="card-wrap">
                                                            <div className="card-title">Succcess Story</div>
                                                            <div className="card-detail">
                                                            <div className="post-img">
                                                           <a href='/image-template/add-image'> <img src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/spotlights-pic+(3).jpg" alt="Success Story" className="img-fluid w-100" /></a>
                                                                </div>
                                                                <div className="font-16 font-500">Facebook Post 400*400 Px</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="card-wrap">
                                                            <div className="card-title">Future Leader 1</div>
                                                            <div className="card-detail">
                                                                <div className="post-img">
                                                                <a href='/image-template/future-leaders'>   <img src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/feature-2.jpg" alt="Success Story" className="img-fluid w-100" /></a>
                                                                </div>
                                                                <div className="font-16 font-500">Facebook Post 400*400 Px</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="card-wrap">
                                                        <div className="card-title">Future Leader 2 </div>
                                                            <div className="card-detail">
                                                                <div className="post-img">
                                                                    <a href='/image-template/future-leaders-three'><img src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/feature-4.jpg" alt="Success Story" className="img-fluid w-100" /></a>
                                                                </div>
                                                                <div className="font-16 font-500">Facebook Post 400*400 Px</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="card-wrap">
                                                            <div className="card-title">Future Leader 4</div>
                                                            <div className="card-detail">
                                                            <div className="post-img">
                                                           <a href='/image-template/future-leaders-four'> <img src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/feature-3.jpg" alt="Success Story" className="img-fluid w-100" /></a>
                                                                </div>
                                                                <div className="font-16 font-500">Facebook Post 400*400 Px</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="card-wrap">
                                                            <div className="card-title">Webinar Certificate</div>
                                                            <div className="card-detail">
                                                            <div className="post-img">
                                                           <a href='/image-template/webinar-certificate'> <img src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/spotlights-pic+(5).jpg" alt="Success Story" className="img-fluid w-100" /></a>
                                                                </div>
                                                                <div className="font-16 font-500">Certificate 1220*860 Px</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ImageTemplate;
