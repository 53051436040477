import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';

class UploadUserService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};

        return Promise.all([
            {},
            [],
            [],
            // this.getaddedByDropdown(),
        ]).then((values) => {
            state.formConfig = {};
            state.data = {};
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }
}
export default UploadUserService;
