import { useContext } from 'react';
import { axiosClient } from "../../library/Service/axiosClient";


const FormListService = () => {

    // Get the all user based on the search filters
    async function allForms(instituteValue) {
        console.log(instituteValue, 'instituteValue all users')
        try {
            const usersData = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getFormList/${instituteValue}`);

            console.log(usersData?.data, 'markersData data list')
    
            // Ensure markersData?.data is an array and transform if necessary
            return usersData?.data?.map((item) => ({
                code: item.widget_inst_id,
                value: item.widget_name,
                category_code: "FORM_WITH_INSTITUTE",
                parentCode: item.institute_id,
                display_order: 1,
                flex_col: {},
                is_active: 1,
            })) || [];
        } catch (error) {
            console.error('Error fetching all users:', error);
            return []; // Return an empty array in case of error
        }
    }

    return {
        allForms
    };

};

export default FormListService;