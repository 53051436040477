import { useContext, useEffect } from "react";
import { DispatchEvent, State } from "../../../../../../library/Core/SmartTypes";
import { SmartContext } from "../../../../../../library/Core/SmartContext";
import PageBuilder from "../../../../../../library/Builders/PageBuilder/PageBuilder";
import EditAffinityFormLayout from "./EditAffinityFormLayout";
import ProfileMarkerService from "./ProfileMakerService";

const EditAffinityModal = ({ userdata }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { saveMarker, fetchUserProfileMarkers } = ProfileMarkerService();

    const handleSave = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();
        const validateOnlineReviews = (reviews: any[]) => {
            console.log("reviews", reviews);
        
            const filteredReviews = reviews.filter(review => 
                review && typeof review === 'object' && Object.keys(review).length > 0 &&
                review.onlineReviewsName !== undefined && review.onlineReviewsName !== ''
            );
        
            console.log("filteredReviews", filteredReviews);
        
            const names = new Set();        
            for (const review of filteredReviews) {
                if (names.has(review.onlineReviewsName)) {
                    return false; 
                }
                names.add(review.onlineReviewsName);
            }
            return true; 
        };        
        
        let onlineReviews = state.data.onlineReviews;
            if (!Array.isArray(onlineReviews)) {
            alert("Online reviews data is in an incorrect format. Please check the data source.");
            return;
        }
                
        if (!validateOnlineReviews(onlineReviews)) {
            alert("Duplicate online Reviews Name detected. Please correct the entries.");
            return; 
        }

        if (state?.data?.visibleControl === 'admissionReferralsManual') {
            const referralsValue = state?.data?.affinity?.admissionReferralsManual;
            console.log("referralsValue",referralsValue)
            const referralsValueStr = String(referralsValue).trim();

            // Check if the value is valid, not empty, and not negative
            if (isNaN(referralsValue) || referralsValueStr === '' || parseInt(referralsValueStr) < 0) {
                alert("Please enter a valid Referrals value.");
                return;
            }
        }
        
        let payload = {
            markerType: 'affinity',
            ...state?.data?.affinity,
        };

        console.log("recognitionbyInstitute",state?.data?.recognitionbyInstitute)
        const controlMapping: { [key: string]: string } = {
            'networkingEvents': 'networkingEvents',
            'lAndDEvents': 'lAndDEvents',
            'recognitionbyInstitute': 'recognitionbyInstitute',
            'managementInteractions': 'managementInteractions',
            'onlineReviews':'onlineReviews'
        };

        const controlType = controlMapping[state?.data?.visibleControl];
        if (controlType && Array.isArray(state?.data[controlType])) {
            const filteredArray = state.data[controlType].filter((item: any) => {
                return item !== undefined &&
                    item !== null &&
                    typeof item === 'object' &&
                    Object.values(item).some(value => value !== undefined && value !== null && value !== '');
            });
            payload.markerType = controlType;

            if (filteredArray.length > 0) {
                payload[controlType] = JSON.stringify(filteredArray);
            } else {
                payload[controlType] = '[{}]';  
            }
        }

        await saveMarker({...payload,syncData : state?.data?.syncData?.[0] || {}}, state?.internal?.model?.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showAffinityModal' } });

    };

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSave } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditAffinityFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditAffinityFormLayout>
        </SmartContext.Provider>
    );
};

export default EditAffinityModal;
