import { useContext, useEffect } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import SessionContext from '../../library/Core/SessionContext';
import { axiosClient } from '../../library/Service/axiosClient';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
// import AlbumListingService from './AlbumListingService';

const useSocialPost = () => {
    // const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const TAB_STATUS_MAP: any = {
        'published': 'Published',
        'drafts': 'Draft',
        'scheduled': "Scheduled"
    }
    // const likeAlbum = async (id: string, refresh = true) => {
    //     const response = await axiosClient().post(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories/${id}/like`);
    //     if (response.status === 200 && refresh) refreshOnLikeOrSave(id, 'LIKE');
    // };

    // const saveAlbum = async (id: string, refresh = true) => {
    //     const response = await axiosClient().post(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories/${id}/save`);
    //     if (response.status === 200 && refresh) refreshOnLikeOrSave(id, 'SAVE');
    // };

    // useEffect(() => {
    //     console.log(" TAB CHNAGED : ", state?.internal?.selectedTab)
    //     tabChange(state?.internal?.selectedTab);
    // }, [state?.internal?.selectedTab]);

    const tabChange = async (tab: string, state: State, dispatch: any) => {
        console.log(" TAB CHNAGE TRIGGERED : ", tab)

        // const response = await axiosClient().get(`${process.env.REACT_APP_MEMORIES_GUIDANCE_SERVER}/memories?searchBy=${tab}`);
        let filteredData: any = [];
        const studentId = sessionState?.studentId;

        if (tab === 'all') {
            filteredData = state.data.all;
        } else if (tab == 'published') {
            filteredData = state.data.all.filter((rec: any) => rec.status?.toLowerCase() == 'published');
        } else if (tab == 'drafts') {
            filteredData = state.data.all.filter((rec: any) => rec.status?.toLowerCase() == 'draft');
        } else if (tab == 'scheduled') {
            filteredData = state.data.all.filter((rec: any) => rec.status?.toLowerCase() == 'scheduled');
        }

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    all: state.data.all,
                    filtered: filteredData,
                }
            },
        });
    };
    
    const refreshListing = async (state: State, dispatch: any) => {
        let apiUrl = `${process.env.REACT_APP_COMMON_API}/social/get-socialmedia-posts`
        const response =  await axiosClient().get(apiUrl);

        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    all: response?.data?.data || [],
                    filtered: response?.data?.data?.filter((rec: any) => rec.status == TAB_STATUS_MAP[state?.internal?.selectedTab]) || []
                }
            },
        });
    };

    // const updateAlbumProperty = (album: any, action: string, property: string) => {
    //     if (action === 'LIKE' && property === 'isLiked') {
    //         return { ...album, isLiked: !album.isLiked };
    //     } else if (action === 'SAVE' && property === 'isFavorite') {
    //         return { ...album, isFavorite: !album.isFavorite };
    //     }
    //     return album;
    // };

    // const refreshOnLikeOrSave = (id: string, action: string) => {
    //     const studentId = sessionState?.studentId;
    //     const updatedAlbums = state.data.albums.map((album: any) =>
    //         album.id === id ? updateAlbumProperty(album, action, action === 'LIKE' ? 'isLiked' : 'isFavorite') : album
    //     );

    //     const updatedFilteredData = state.data.filteredData.map((album: any) =>
    //         album.id === id ? updateAlbumProperty(album, action, action === 'LIKE' ? 'isLiked' : 'isFavorite') : album
    //     );

    //     const summaryCounts = {
    //         totalCount: updatedAlbums.length,
    //         mineCount: updatedAlbums.filter((album: any) => album.studentId == studentId).length,
    //         savedCount: updatedAlbums.filter((album: any) => album.isFavorite).length,
    //     };

    //     dispatch({
    //         type: 'REFRESH_DATA',
    //         payload: { data: { ...state.data, albums: updatedAlbums, filteredData: updatedFilteredData, summaryCounts } },
    //     });
    // };

    return { tabChange, refreshListing };
};

export default useSocialPost;