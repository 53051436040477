import { isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    // let customValidationPassed = true;
    if (!isImpersonatedSession && ((!isFormValid(state, dispatch,  ['jobsApplied.*.companyId','jobOutcome.companyId']) || !customValidationPassed) || !inValidDate)) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let jobOutcome = state?.data['jobOutcome'];
    const appliedForThePlacement = state?.data['jobOutcome'].isCampusPlacement == false ? false : state?.data['profile'].appliedForThePlacement;
    
    let jobsApplied = (state?.data['profile'].appliedForThePlacement == true && state?.data['jobOutcome'].isCampusPlacement == true) ? state?.data['jobsApplied'].map((job:any) => {
        return {...job,isOutcome : true};
    }) : [];
    
    let profile = {...state?.data['profile'] , appliedForThePlacement: appliedForThePlacement};

    let jobs = [{...jobOutcome,recordTypeCode:'JOINED',isOutcome : true},
                ...jobsApplied];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobs,
            profile: profile,
        })
    ).then((response) => {
        if (response?.status === 200) {
              // Send event to Moengage job outcome
            sendMoEngageEvent(state?.data);
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            })

        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const jobOutcome = state.data.jobOutcome;

    if (isEmpty(jobOutcome?.companyId) && isEmpty(jobOutcome?.otherCompany)) {
        isFormInvalid = true;
        setError(`jobOutcome.companyId`, [`Please enter "Which company did you join?"`], dispatch);
    } else {
        setError(`jobOutcome.companyId`, [], dispatch);
    }

    jobs.forEach((job: any, index: number) => {

        if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
            isFormInvalid = true;
            setError(`jobsApplied.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
        } else {
            setError(`jobsApplied.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if ( isFormInvalid ) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobsApplied', errorMessages },
        });
        
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobOutcome', errorMessages },
        });
    }
    
    return !isFormInvalid;
};


export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.jobs.map((row: any, index: number) => {
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });

    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'jobs', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const job = state?.data['jobOutcome'];
    let isFormValid = true;
    
        console.log(job);

        if (isEmpty(job?.startDate)) {
            setError(`jobOutcome.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`jobOutcome.startDate`, [], dispatch);
        }

        if ((job?.isCurrent === false || isEmpty(job?.isCurrent)) && isEmpty(job?.endDate)) {
            setError(`jobOutcome.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`jobOutcome.endDate`, [], dispatch);
        }

        // if (new Date(job?.startDate) >= new Date(job?.endDate)) {
        //     console.log('Invalid date');
        // }
        
        if (job?.isCurrent === false || isEmpty(job?.isCurrent)) {
            if (new Date(job?.startDate) > new Date(job?.endDate)) {
                setError(`jobOutcome.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`jobOutcome.endDate`, [], dispatch);
            }
        }

    return isFormValid;
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-job-outcome`, {
            userInfo: user,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
};
