import {
    arrayColumn,
    arrayUnique,
    getDomainValueForCode,
    getDomainValueForCodeUsingParent,
    isEmpty,
    isSchoolAdmin,
    toSentenceCase,
} from '../../../../library/Core/SmartFunctions';

import { axiosClient } from '../../../../library/Service/axiosClient';

export const getProfileView = (state) => {
    const model = {};
    model.isEditable = state.context.studentId == state.data.id;

    if (state.context.userTypeCode === 'SUPER_ADMIN' || state.context.userTypeCode === 'INSTITUTE_ADMIN') {
        model.isEditable = true;
    }

    model.id = state.data.id;
    model.firstName = toSentenceCase(state.data.user.firstName);
    model.lastName = toSentenceCase(state.data.user.lastName);
    model.profileImage = state.data.profile.profilePictureFilePath;
    model.cityName = getDomainValueForCode(state?.data?.profile?.residingCityCode, 'CITY_CODE', state);
    model.countryName = getDomainValueForCode(state?.data?.profile?.residingCountryCode, 'COUNTRY_CODE', state);
    model.passOutYear = state.data.profile.passOutYear;
    model.mobileCountryCode = state.data.user.mobileCountryCode;
    model.mobileNumber = state.data.user.mobileNumber;
    model.registeredEmailId = state.data.user.registeredEmailId;
    model.schoolName = state?.context?.instituteName;
    model.class = state?.context?.classCode ?? '';
    model.curriculum = state?.context?.curriculumCode;
    model.class12thCurriculumCode = getDomainValueForCode(state?.data?.profile?.class12thCurriculumCode, 'SCHOOL_CURRICULUM_CODE', state);
    model.isEmailVerified = state.data.user.isEmailVerified;
    model.isMobileVerified = state.data.user.isMobileVerified;
    model.primaryCitizenship = getDomainValueForCode(state?.data?.profile?.primaryCitizenshipCode, 'COUNTRY_CODE', state);
    model.residingCity = getDomainValueForCode(state?.data?.profile?.residingCityCode, 'CITY_CODE', state);
    model.residingCountry = getDomainValueForCode(state?.data?.profile?.residingCountryCode, 'COUNTRY_CODE', state);

    model.currentStatus = getDomainValueForCode(
        state?.data?.profile?.educationOrEmploymentCurrentStatusCode,
        'EDUCATIONAL_OR_EMPLOYMENT_STATUS_CODE',
        state
    );
    // Education Details
    model.educationDetails = getEducationInfo(state.data.schools, state);
    //model.classTenEducationDetails = model.educationDetails.find((item) => item.recordTypeCode === 'CLASS_10');
    // model.classTwelveEducationDetails = model.educationDetails.find((item) => item.recordTypeCode === 'CLASS_12');

    // Entrance Test
    model.entranceTests = getEntranceTests(state.data.entranceTests, state);

    // UnderGrad Colleges
    model.underGradColleges = getCollegeInfo(state.data?.underGradColleges, state);
    model.joinedCollege = model?.underGradColleges.find((college) => college?.recordTypeCode === 'JOINED');

    // Post Grad Colleges
    model.postGradColleges = getCollegeInfo(state.data.postGradCollege, state);

    // Work Experience
    model.workExperience = getWorkExperience(state.data.jobsList, state);

    // Mentoring Interests
    // model.mentoringInterests = getMentoringInterests(state.data.mentoringInterests, state);
    model.mentoringInterests = state.data.mentoringInterests;

    // Achievements
    model.achievements = getAchievements(state.data.achievements, state);
    model.achievements = state.data.achievements.map((achievement) => {
        return {
            ...achievement,
            level: getDomainValueForCodeUsingParent(
                achievement?.levelCode,
                'ACHIEVEMENT_LEVEL_CODE',
                state,
                achievement?.programCategoryCode
            ),
        };
    });

    // Achievements
    model.currentSkill = getCurrentSkills(state.data.skills, state);
    // model.targetSkill = getTargetSkills(state.data.skills, state);
    model.targetSkill = getTargetSkills(state.data.skillInfo?.targetSkillCode?.split(','), state);

    // Education Preferences
    model.preferredProgramType =
        getDomainValueForCode(state.data.educationPreferenceInfo.preferredPgProgramTypeCode, 'PG_STUDY_PROGRAM_CODE', state) ??
        getDomainValueForCode(state.data.educationPreferenceInfo.preferredUgProgramTypeCode, 'UG_STUDY_PROGRAM_CODE', state) ??
        '';

    model.preferredModeOfProgramCode =
        getDomainValueForCode(state.data.educationPreferenceInfo.preferredModeOfProgramCode, 'NATURE_OF_STUDY_CODE', state) ?? '';

    model.preferredProgramStartOn =
        getDomainValueForCode(state.data.educationPreferenceInfo.startOn, 'PG_HIGHER_STUDY_START_ON', state) ?? '';

    model.fieldOfStudyPreferences = state.data.educationFieldOfStudyPreferences.map((field) => {
        return {
            // fieldOfStudyName: getDomainValueForCode(field.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE', state),
            fieldOfStudyName: getDomainValueForCode(field.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state),
            preferenceColorCode: field.fieldOfStudyPreferenceCode === 'TOTALLY_SURE' ? 'green' : 'red',
        };
    });

    model.parents = state.data.parents.map((field) => {
        const countryCode = getDomainValueForCode(field.mobileCountryCode, 'MOBILE_COUNTRY_CODE', state);
        return {
            id: field.id,
            relation: field.parentTypeCode,
            name: field.firstName + ' ' + field.lastName,
            contactEmail: field.contactEmail,
            contactNumber: (field.mobileCountryCode ? countryCode : 'India (+91) ') + field.mobileNumber,
        };
    });

    model.locationPreferences = state.data.educationCountryPreferences.map((location) => {
        return {
            countryName: getDomainValueForCode(location.countryCode, 'COUNTRY_CODE', state),
            preferenceColorCode: location.countryPreferenceCode === 'TOTALLY_SURE' ? 'green' : 'red',
        };
    });

    model.universityPreferences = getCollegeInfo(state.data.educationUniversityPreferences, state);

    // Job Preferences
    model.preferredFunctionalArea = getDomainValueForCode(model.preferredFunctionalAreaCode, 'FUNCTIONAL_AREA_CODE', state);
    model.jobIndustryPreferences = state.data.jobIndustryPreferences.map((industry) => {
        return {
            industryCode: industry.industryCode,
            industryName: getDomainValueForCode(industry.industryCode, 'INDUSTRY_CODE', state),
            preferenceColorCode: 'green',
        };
    });
    model.jobLocationPreferences = state.data.jobLocationPreferences.map((location) => {
        return {
            cityCode: location.cityCode,
            cityName: getDomainValueForCode(location.cityCode, 'CITY_CODE', state),
            preferenceColorCode: location.preferenceCode === 'TOTALLY_SURE' ? 'green' : 'red',
        };
    });

    model.jobCompanyPreferences = state.data.jobCompanyPreferences
        .filter((company) => !isEmpty(company.companyId) || company?.otherCompany.length > 0)
        .map((company) => ({
            ...company,
            companyName: !isEmpty(company?.companyId)
                ? getDomainValueForCode(company?.companyId, 'COMPANY_CODE', state)
                : company?.otherCompany,
        }));

    // Books
    model.books = state.data.books;
    model.activities = state.data.activities;

    return model;
};

export const getEducationInfo = (education, state) =>
    education.map((edu) => {
        let schoolName = getDomainValueForCode(edu.schoolId, 'SCHOOL_CODE', state) ?? '';
        return {
            // recordTypeCode: edu.recordTypeCode,
            // schoolName: getDomainValueForCode(edu.schoolId, 'SCHOOL_CODE', state) ?? '',
            schoolName: schoolName,
            // class10SchoolName: edu.class10SchoolName ?? '',
            id: edu.id,
            class10SchoolName: edu.class10SchoolName ?? schoolName,
            curriculum: getDomainValueForCode(edu.curriculumCode, 'SCHOOL_CURRICULUM_CODE', state) ?? '',
            curriculumCode: edu.curriculumCode,
            endYear: edu.endYear ?? '',
            marks: edu.marks ?? '',
            classCode: edu.classCode ?? '',
            cieGrade: edu.cieGrade,
            subjectWiseMarks: edu.subjectWiseMarks,
        };
    });

export const getEntranceTests = (entranceTests, state) =>
    entranceTests.map((test) => {
        return {
            id: test.id,
            entranceTestName: isEmpty(test.entranceTestCode)
                ? test.otherExam
                : getDomainValueForCode(test.entranceTestCode, 'ENTRANCE_TEST_CODE', state),
            preparationStatus: getDomainValueForCode(test.preparationStatusCode, 'ENTRANCE_EXAM_PREPARATION_STATUS', state),
        };
    });

export const getCollegeInfo = (universities, state) => {
    return universities
        .sort((a, b) => (b.recordTypeCode ?? '').localeCompare(a.recordTypeCode ?? ''))
        .filter((college) => !isEmpty(college?.universityId) || !isEmpty(college?.otherUniversity))
        .map((college) => {
            const flexColJsonStr = state?.domain?.get('UNIVERSITY_CODE').find((rec) => rec.code == college?.universityId)?.flexCol;
            const flexCol = flexColJsonStr ? JSON.parse(flexColJsonStr) : {};
            // const flexCol = JSON.parse(state?.domain?.get('UNIVERSITY_CODE').find((rec) => rec.code == '11777')['flexCol']);
            const getScholarship = () => {
                if (college.scholarshipTypeCode === 'AMOUNT')
                    return `${getDomainValueForCode(college.scholarshipCurrencyCode, 'CURRENCY_CODE', state)}:${college.scholarshipAmount}`;
                if (college.scholarshipTypeCode === 'PERCENT') return `${college.scholarshipAmount}%`;
                return '';
            };
            const alumniCount =
                state?.data?.universityAlumniCountsJson?.find((e) => e.universityId == college.universityId)?.alumniCount ?? 0;

            const pgalumniCount =
                state?.data?.pgUniversityAlumniCountsJson?.find((e) => e.pgUniversityId == college.universityId)?.pgAlumniCount ?? 0;

            return {
                universityId: college.universityId,
                universityName: !isEmpty(college?.universityId)
                    ? getDomainValueForCode(college?.universityId, 'UNIVERSITY_CODE', state)
                    : college?.otherUniversity,
                fieldOfStudy: getDomainValueForCode(college?.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE', state),
                cityName: getDomainValueForCode(flexCol.cityCode, 'CITY_CODE', state),
                stateName: getDomainValueForCode(flexCol.stateCode, 'STATE_CODE', state),
                countryName: getDomainValueForCode(flexCol.countryCode, 'COUNTRY_CODE', state),
                recordTypeCode: college.recordTypeCode,
                collegeApplicationResultCode: college.collegeApplicationResultCode,
                // logoFileName: flexCol.fileName ?? 'no_university_img.svg',
                logoFileName:
                    flexCol.fileName && flexCol.fileName != 'no_img.png'
                        ? flexCol.fileName
                        : `${process.env.REACT_APP_IMAGE_BASEPATH}/no_university_img.svg`,
                websiteUrl: flexCol.websiteUrl,
                establishedYear: flexCol.vintage,
                scholarship: getScholarship(),
                alumniCount,
                pgalumniCount,
            };
        });
};

const getWorkExperience = (workExperience, state) => {
    console.log(workExperience);
    return workExperience
        .filter((work) => {
            //Teaching and Research, check instituteId or otherInstitute
            if (work.jobCategory === 'TEACHING_AND_RESEARCH') {
                return (work.instituteId || work.otherInstitute) && (work.instituteId > 0 || work?.otherInstitute?.length > 0);
            }

            // other categories, check companyId or otherCompany
            return (
                (work.companyId || work.otherCompany) &&
                (work.companyId > 0 || work?.otherCompany?.length > 0) &&
                (work.jobCategory === 'ENTREPRENEURSHIP' ||  work.jobCategory === 'DEFENCE' ||
                    work.jobCategory === 'PROFESSIONAL_PRACTICE' ||
                    work.recordTypeCode === 'JOINED')
            );
        })
        .map((work) => {
            var startDate = work.startDate ? new Date(work.startDate).getFullYear() : null;
            var endDate = work.endDate ? new Date(work.endDate) : null;
            var endYear = endDate ? endDate.getFullYear() : null;

            return {
                id: work.id,
                // For Teaching and Research, use instituteId or otherInstitute
                companyId: work.jobCategory === 'TEACHING_AND_RESEARCH' ? work.instituteId || work.otherInstitute : work.companyId || '',
                companyName:
                    work.jobCategory === 'TEACHING_AND_RESEARCH'
                        ? work.instituteId
                            ? getDomainValueForCode(work.instituteId, 'INSTITUTE_DOMAIN', state)
                            : work.otherInstitute
                        : work.companyId
                        ? getDomainValueForCode(work.companyId, 'COMPANY_CODE', state)
                        : work.otherCompany,
                designation: work.designation,
                startYear: startDate,
                endYear: work.isCurrent ? 'Present' : endYear,
                isCurrent: work.isCurrent,
                jobCategory: work.jobCategory,
            };
        })
        .sort((a, b) => {
            return b.isCurrent - a.isCurrent;
        });
};

const getMentoringInterests = (mentoringInterests, state) => {
    // return mentoringInterests
    //     .filter((work) => work.companyName && work.companyName.length > 0)
    //     .map((work) => {
    //         var startDate = new Date(work.startDate);
    //         var endDate = work.endDate ? new Date(work.endDate) : null;
    //         var endYear = endDate ? endDate.getFullYear() : null;
    //         return {
    //             id: work.id,
    //             companyName: work.companyName,
    //             designation: work.designation,
    //             startYear: startDate.getFullYear(),
    //             endYear: work.isCurrent ? 'Present' : endYear,
    //             isCurrent: work.isCurrent,
    //         };
    //     })
    //     .sort((a, b) => {
    //         return b.isCurrent - a.isCurrent;
    //     });
};

const getAchievements = (achievements, state) => {
    return achievements.map((achievement) => {
        const achievementDate = new Date(achievement.achievementDate);
        const level =
            state.domain
                .get('ACHIEVEMENT_LEVEL_CODE')
                .filter((rec) => rec.code == achievement.levelCode && rec.parenCode == achievement.programCategoryCode)[0] ?? '';
        return {
            id: achievement.id,
            type: getDomainValueForCode(achievement.typeCode, 'ACHIEVEMENT_CODE', state),
            programCategoryCode: getDomainValueForCode(achievement.programCategoryCode, 'ACHIEVEMENT_CATEGORY_CODE', state),
            level: level.value ?? '',
            title: achievement.title,
            month: achievementDate.toLocaleString('default', { month: 'short' }),
            year: achievementDate.getFullYear(),
            weblink: achievement.weblink ?? '',
        };
    });
};

const getTargetSkills = (skills, state) => {
    // return skills
    //     .filter((skill) => skill.skillLevelCode == null)
    //     .map((skill) => {
    //         return {
    //             id: skill.id,
    //             skillName: getDomainValueForCode(skill.skillCode, 'SKILL_CODE', state),
    //             skillLevelCode: skill.skillLevelCode,
    //             skillLevel: getDomainValueForCode(skill.skillLevelCode, 'SKILL_LEVEL_CODE', state),
    //             type: skill.skillLevelCode ?? 'target',
    //         };
    //     });

    return skills
        ?.filter((skill) => skill != null)
        ?.map((skill) => {
            return getDomainValueForCode(skill, 'SKILL_CODE', state);
        });
};

const getCurrentSkills = (skills, state) => {
    return skills
        .filter((skill) => skill.skillLevelCode != null)
        .map((skill) => {
            let skillStr = skill.otherSkill;
            if (!isEmpty(skill.skillCode)) {
                skillStr = getDomainValueForCode(skill.skillCode, 'SKILL_VIEW_CODE', state);
            }
            return {
                id: skill.id,
                // skillName: getDomainValueForCode(skill.skillCode, 'SKILL_VIEW_CODE', state),
                skillName: skillStr,
                skillLevelCode: skill.skillLevelCode,
                skillLevel: getDomainValueForCode(skill.skillLevelCode, 'SKILL_LEVEL_CODE', state),
                type: skill.skillLevelCode ?? 'target',
            };
        });
};

export const getRelatedAlumni = async (tab, dispatch) => {
    if (tab.filterCriteria === undefined) {
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'matchingAlumni', value: { selectedTab: tab?.route, label: tab?.label, data: [] } },
        });
        return;
    }
    tab.filterCriteria.profile_id = tab.id;

    // const response = await axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/search`, tab.filterCriteria);
    const response = await axiosClient().post(
        `${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/get-similar-alumni`,
        tab.filterCriteria
    );
    dispatch({
        type: 'CONTROL_VALUE_CHANGE',
        payload: {
            dataKey: 'matchingAlumni',
            value: { selectedTab: tab.route, label: tab.label, data: response.data?.students ?? [] },
        },
    });
};

export const getTabsConfig = (forStudentId, state, context) => {
    const tabs = [];
    const currentUser = context.studentId;
    const userType = context.userTypeCode;

    const achievementData = state?.data?.achievements ?? [];
    const achievementTypeCodeArr = arrayUnique(arrayColumn(achievementData, 'typeCode'), true);

    const skillData = state?.data?.skills ?? [];

    let skillIds = [];
    let otherSkills = [];

    skillData.map((eachSkill) => {
        if (!isEmpty(eachSkill.skillCode) && eachSkill.skillCode > 0) {
            skillIds.push(eachSkill.skillCode);
        } else {
            otherSkills.push(eachSkill.otherSkill);
        }
    });

    if (userType === 'SUPER_ADMIN' || sessionStorage.getItem('is-impersonated-session') === 'YES') {
        // let menuType = getMenuType(state.data.profile)
        // if ( menuType == 'ALUMNI' ) {
        //     return [
        //         { label: 'Office Usage', route: 'office-usage' },
        //         { label: 'Profile Information', route: 'profile-information' },
        //         { label: 'Under Graduate', route: 'ug' },
        //         // { label: 'Post Graduate', route: 'pg', filterCriteria: {} },
        //         { label: 'Post Graduate', route: 'pg', filterCriteria: { pgCollegeExists: state?.data?.postGradCollege?.length > 0 } },
        //         { label: 'Work Experience', route: 'work', filterCriteria: { companyNameExists: state?.data?.jobs?.length > 0 } },
        //         // { label: 'Work Experience', route: 'work', filterCriteria: { companyNameIn: jobCompanyNameData, designationLike: jobDesignationData } },
        //         // { label: 'Achievements', route: 'achievements', filterCriteria: { achievements: [] } },
        //         { label: 'Achievements', route: 'achievements', filterCriteria: { achievementTypeIn: achievementTypeCodeArr } },
        //         { label: 'Contributions', route: 'contributions' },
        //         // { label: 'Skills', route: 'skills', filterCriteria: { skills: [] } },
        //         { label: 'Skills', route: 'skills', filterCriteria: { skillsIn: [skillIds, otherSkills] } },
        //         { label: 'Preferences', route: 'preferences' },
        //         { label: 'Consent', route: 'mentoring-interests' },
        //         { label: 'Benefits', route: 'benefits' },
        //     ];
        // } else {
        //     return [
        //         { label: 'Preference', route: 'preference' },
        //         { label: 'Parent Information', route: 'parent-info' },
        //         { label: 'Education Details', route: 'education' },
        //         { label: 'Entrance Exams', route: 'entrance-exams' },
        //         { label: 'Job Preferences', route: 'job-preferences' },
        //         { label: 'Extra Curricular', route: 'extra-curricular' },
        //     ];
        // }

        return [
            { label: 'Office Usage', route: 'office-usage' },
            { label: 'Profile Information', route: 'profile-information' },
            { label: 'Schooling', route: 'education' },

            { label: 'Under Graduate', route: 'ug' },
            // { label: 'Post Graduate', route: 'pg', filterCriteria: {} },
            { label: 'Post Graduate', route: 'pg', filterCriteria: { pgCollegeExists: state?.data?.postGradCollege?.length > 0 } },
            { label: 'Work Experience', route: 'work', filterCriteria: { companyNameExists: state?.data?.jobs?.length > 0 } },
            // { label: 'Work Experience', route: 'work', filterCriteria: { companyNameIn: jobCompanyNameData, designationLike: jobDesignationData } },
            // { label: 'Achievements', route: 'achievements', filterCriteria: { achievements: [] } },
            { label: 'Achievements', route: 'achievements', filterCriteria: { achievementTypeIn: achievementTypeCodeArr } },
            { label: 'Contributions', route: 'contributions' },
            // { label: 'Skills', route: 'skills', filterCriteria: { skills: [] } },
            { label: 'Skills', route: 'skills', filterCriteria: { skillsIn: [skillIds, otherSkills] } },
            { label: 'Preferences', route: 'preferences' },
            { label: 'Consent', route: 'mentoring-interests' },
            { label: 'Benefits', route: 'benefits' },

            { label: '3C Preference', route: 'preference' },
            { label: 'Parent Information', route: 'parent-info' },
            // { label: 'Education Details', route: 'education' },
            { label: 'Entrance Exams', route: 'entrance-exams' },
            // { label: 'Job Preferences', route: 'job-preferences' },
            { label: 'Extra Curricular', route: 'extra-curricular' },
            { label: 'Guidance Videos', route: 'guidance-videos' },
        ];
    }

    // if (forStudentId == currentUser) {
    //     return [
    //         { label: 'Under Graduate', route: 'ug' },
    //         // { label: 'Post Graduate', route: 'pg', filterCriteria: {} },
    //         { label: 'Post Graduate', route: 'pg', filterCriteria: { pgCollegeExists: '-NA-' } },
    //         { label: 'Work Experience', route: 'work', filterCriteria: { companyNameExists: '-NA-' } },
    //         // { label: 'Work Experience', route: 'work', filterCriteria: { companyNameIn: jobCompanyNameData, designationLike: jobDesignationData } },
    //         // { label: 'Achievements', route: 'achievements', filterCriteria: { achievements: [] } },
    //         { label: 'Achievements', route: 'achievements', filterCriteria: { achievementTypeIn: achievementTypeCodeArr } },
    //         { label: 'Contributions', route: 'contributions' },
    //         // { label: 'Skills', route: 'skills', filterCriteria: { skills: [] } },
    //         { label: 'Skills', route: 'skills', filterCriteria: { skillsIn: [skillIds, otherSkills] } },
    //         { label: 'Preferences', route: 'preferences' },
    //         { label: 'Benefits', route: 'benefits' },
    //         { label: 'Entrance Exams', route: 'entrance-exams' },

    //     ];
    // }

    if (forStudentId == currentUser) {
        // if ( state.data.profile.pursuingEducationLevelCode == 'K12' ) {
        if (context.tabType == 'STUDENT') {
            return [
                { label: 'Schooling', route: 'education' },
                { label: 'Preference', route: 'preference' },
                { label: 'Parent Information', route: 'parent-info' },
                // { label: 'Education Details', route: 'education' },
                { label: 'Entrance Exams', route: 'entrance-exams' },
                { label: 'Job Preferences', route: 'job-preferences' },
                { label: 'Extra Curricular', route: 'extra-curricular' },
            ];
        } else {
            return [
                { label: 'Schooling', route: 'education' },
                { label: 'Under Graduate', route: 'ug' },
                { label: 'Post Graduate', route: 'pg', filterCriteria: { pgCollegeExists: state?.data?.postGradCollege?.length > 0 } },
                { label: 'Work Experience', route: 'work', filterCriteria: { companyNameExists: state?.data?.jobs?.length > 0 } },
                { label: 'Achievements', route: 'achievements', filterCriteria: { achievementTypeIn: achievementTypeCodeArr } },
                { label: 'Contributions', route: 'contributions' },
                { label: 'Skills', route: 'skills', filterCriteria: { skillsIn: [skillIds, otherSkills] } },
                { label: 'Preferences', route: 'preferences' },
                { label: 'Benefits', route: 'benefits' },
            ];
        }
    }

    //if (userType === 'SCHOOL_ADMIN') {
    // if (isSchoolAdmin()) {
    //     tabs.push({ label: 'Student Interaction', route: 'interaction' });
    // }
    if (context.tabType == 'ALUMNI') {
        // @UAS-471-start @dt 21st.May,2K24 @by RG;
        if (isSchoolAdmin()) {
            tabs.push({ label: 'Student Interaction', route: 'interaction' });
            tabs.push({ label: 'Office Usage', route: 'profile-marker' });
        }
        // @UAS-471-end;

        //if (state?.data?.schools?.length > 0) {
        tabs.push({ label: 'Schooling', route: 'education' });
        //}

        //if (state?.data?.underGradColleges?.length > 0) {
        tabs.push({ label: 'Under Graduate', route: 'ug' });
        //}

        //if (state?.data?.postGradCollege?.length > 0) {
        // tabs.push({ label: 'Post Graduate', route: 'pg', filterCriteria: {} });
        tabs.push({ label: 'Post Graduate', route: 'pg', filterCriteria: { pgCollegeExists: true } });
        //}
        // if (state?.data?.workExperience?.length > 0) {
        //if (state?.data?.jobs?.length > 0) {
        tabs.push({ label: 'Work Experience', route: 'work', filterCriteria: { companyNameExists: true } });
        // tabs.push({ label: 'Work Experience', route: 'work', filterCriteria: { companyNameIn: jobCompanyNameData, designationLike: jobDesignationData } });
        //}
        if (state?.data?.achievements?.length > 0) {
            // tabs.push({ label: 'Achievements', route: 'achievements', filterCriteria: { achievements: [] } });
            tabs.push({ label: 'Achievements', route: 'achievements', filterCriteria: { achievementTypeIn: achievementTypeCodeArr } });
        }

        // tabs.push({ label: 'Contributions', route: 'contributions' });
        // if ( state?.data?.jobs?.length > 0 || state?.data?.jobs?.length < 0 || state?.data?.jobs?.length == 0) {
        tabs.push({ label: 'Contributions', route: 'contributions' });
        // }

        if (state?.data?.currentSkills?.length > 0 || state?.data?.targetSkills?.length > 0) {
            // tabs.push({ label: 'Skills', route: 'skills', filterCriteria: { skills: [] } });
            tabs.push({ label: 'Skills', route: 'skills', filterCriteria: { skillsIn: [skillIds, otherSkills] } });
        }
        if (state?.data?.educationPreferenceInfo?.length > 0 || state?.data?.targetSkill?.length > 0) {
            tabs.push({ label: 'Preferences', route: 'preferences' });
        }

        if (!isEmpty(state?.data?.benefits) && Object.values(state?.data?.benefits).some((filter) => filter.length > 0)) {
            tabs.push({ label: 'Benefits', route: 'benefits' });
        }
    } else {
        // @dt 23rd.May,2K24 @by RG @note commented teh Student Interaction tab as it should not be displayed for student profiles, As suggested by QA (subba).
        // if (isSchoolAdmin()) {
        //     tabs.push({ label: 'Student Interaction', route: 'interaction' });
        // }
        tabs.push({ label: 'Preference', route: 'preference' });
        tabs.push({ label: 'Parent Information', route: 'parent-info' });
        tabs.push({ label: 'Schooling', route: 'education' });
        tabs.push({ label: 'Entrance Exams', route: 'entrance-exams' });
        tabs.push({ label: 'Job Preferences', route: 'job-preferences' });
        tabs.push({ label: 'Extra Curricular', route: 'extra-curricular' });
    }

    // if (state?.data?.underGradColleges?.length > 0) {
    //     tabs.push({ label: 'Under Graduate', route: 'ug' });
    // }

    // if (state?.data?.postGradCollege?.length > 0) {
    //     // tabs.push({ label: 'Post Graduate', route: 'pg', filterCriteria: {} });
    //     tabs.push({ label: 'Post Graduate', route: 'pg', filterCriteria: { pgCollegeExists: true } });
    // }
    // // if (state?.data?.workExperience?.length > 0) {
    // if (state?.data?.jobs?.length > 0) {
    //     tabs.push({ label: 'Work Experience', route: 'work', filterCriteria: { companyNameExists: true } });
    //     // tabs.push({ label: 'Work Experience', route: 'work', filterCriteria: { companyNameIn: jobCompanyNameData, designationLike: jobDesignationData } });
    // }
    // if (state?.data?.achievements?.length > 0) {
    //     // tabs.push({ label: 'Achievements', route: 'achievements', filterCriteria: { achievements: [] } });
    //     tabs.push({ label: 'Achievements', route: 'achievements', filterCriteria: { achievementTypeIn: achievementTypeCodeArr } });
    // }

    // tabs.push({ label: 'Contributions', route: 'contributions' });

    // if (state?.data?.currentSkills?.length > 0 || state?.data?.targetSkills?.length > 0) {
    //     // tabs.push({ label: 'Skills', route: 'skills', filterCriteria: { skills: [] } });
    //     tabs.push({ label: 'Skills', route: 'skills', filterCriteria: { skillsIn: [skillIds, otherSkills] } });
    // }
    // if (state?.data?.educationPreferenceInfo?.length > 0 || state?.data?.targetSkill?.length > 0) {
    //     tabs.push({ label: 'Preferences', route: 'preferences' });
    // }

    // if (Object.values(state?.data?.benefits).some(filter => filter.length > 0)) {
    //     tabs.push({ label: 'Benefits', route: 'benefits' });
    // }

    return tabs;
};

export const getFieldOfStudyName = (fieldOfStudyCode, state) =>
    getDomainValueForCode(fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state);
