import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { ControlOnChangeArguments } from '../../../library/Core/SmartTypes';
import WidgetConfigurationLayout from '../WidgetConfigurationLayout';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';

const CoverBanner = () => {
    const { state, dispatch } = useContext(SmartContext);
    let {id, code} = useParams();
    if ( typeof code == 'undefined') {
        code = '';
    }

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, introLink: onUrlChange } });
    }, []);


    function onUrlChange(props: ControlOnChangeArguments) {
        console.log(props, " props 1111 ");
        let url = props?.value;
        let videoId, platform, embedUrlPrefx, embedUrl, matchRegEx;
        
        try {
            const youtubeMatchRegEx = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.+?\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const vimeoMatchRegEx = /vimeo\.com\/(?:.*\/)?(\d+)/;

            if (youtubeMatchRegEx.test(url)) {
                platform = 'youtube';
                matchRegEx = youtubeMatchRegEx;
                embedUrlPrefx = `https://www.youtube.com/embed/`;
            } else if (vimeoMatchRegEx.test(url)) {
                platform = 'vimeo';
                matchRegEx = vimeoMatchRegEx;
                embedUrlPrefx = `https://player.vimeo.com/video/`;
            } else {
                throw new Error("Invalid video source..!");
            }
        
            // Extract video ID from the URL
            const match = url.match(matchRegEx);
            if (match) {
                videoId = match[1];
                embedUrl = `${embedUrlPrefx}${videoId}`;
                // console.log(`Platform: ${platform}, Video ID: ${videoId}`);
            } else {
                throw new Error("Video ID extraction failed.");
            }
        } catch (error: any) {
            console.error(error.message);
        }        

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.embedUrl`,
                value: embedUrl,
            },
        });

        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: `${props.parentDataKey}.platform`,
                value: platform,
            },
        });
    };

    return (
        <WidgetConfigurationLayout state={state} >{!state.flags.isDataLoading && <PageBuilder />}</WidgetConfigurationLayout>
    );
};

export default CoverBanner;