import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import smartReducer from '../../../library/Core/SmartReducer';
import { SmartContext } from '../../../library/Core/SmartContext';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import UserUploadLayout from './UserUploadLayout';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
const UploadUser = () => {
    const { globalState } = useContext(GlobalContext);
    const { id, pageName } = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    // console.log('data in main component');
    // console.log(data);
    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain: new Map([...globalState.domain]), internal, routeInfo },
        });
    }, [data]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && <UserUploadLayout>{!state.flags.isDataLoading && <PageBuilder />}</UserUploadLayout>}
        </SmartContext.Provider>
    );
};
export default UploadUser;
