import React, { useContext, useEffect } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { SmartContext } from '../../library/Core/SmartContext';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
import useSocialPost from './useSocialPost';
import { Tooltip } from 'bootstrap';
import { initTooltip, isEmpty } from '../../library/Core/SmartFunctions';
import Swal from 'sweetalert2';
import { axiosClient } from '../../library/Service/axiosClient';

function SocialPostList() {
    // const { state, dispatch } = useContext(SmartContext);
    const { internal, data } = useLoaderData() as State;
    const navigate = useNavigate();
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
        internal,
        data,
    } as any);
    const { tabChange, refreshListing } = useSocialPost();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    console.log(state, ' STATE in socialpost L I S T ');
    const handleTabSelect = (tab: string) => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'selectedTab', value: tab } });
        tabChange(tab, state, dispatch);
    };

    const handleReUse = (id: number) => {
        console.log(id, ' Reuse ID');
        Swal.fire({
            title: 'Are you sure you want to copy this post?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            // denyButtonText: `No`
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/social/re-use/${id}`);
                await Swal.fire(`${response?.data?.message}`, '', `${response?.data?.success == 1 ? 'success' : 'warning'}`);

                if (response?.data?.success == 1 && !isEmpty(response?.data?.id)) {
                    navigate(`/socialmedia/socialpost/${response?.data?.id}`);
                    Toast.fire({
                        icon: `success`,
                        title: `Saved as Draft..!`,
                    });
                }
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            }
        });
    };

    const handleDiscard = async (id: number, schedule_time: any, status: any) => {
        console.log(id, ' Discard ID');
        console.log(status, " STATUS")
        console.log(schedule_time, " schedule_time")
        
        Swal.fire({
            title: 'Are you sure you want to discard this post?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            // denyButtonText: `No`
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (status?.toLowerCase() === 'scheduled' && ! canDiscard(schedule_time)) {
                    await Swal.fire(`Unable to discard..!`, 'This scheduled post is close to its publication time..!', `warning`);
                    refreshListing(state, dispatch);
                    return;
                }
                const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/social/delete/${id}`);
                await Swal.fire(`${response?.data?.message}`, '', `${response?.data?.success == 1 ? 'success' : 'warning'}`);

                if (response?.data?.success == 1) {
                    // navigate(`/socialmedia/posts`);
                    refreshListing(state, dispatch);
                }
            }
        });

    };

    useEffect (() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        initTooltip(Tooltip);
    }, [state.internal.selectedTab]);

    const canDiscard = (schedule_time: any) => {
        const currentTime: any = new Date();
        const scheduleTime: any = new Date(schedule_time);

        // Calculate the difference in milliseconds between current time and schedule time
        const timeDifference = scheduleTime - currentTime;
        console.log(timeDifference, " timeDifference ")
        // Check if the time difference is less than 1 hour (3600000 milliseconds)
        if (timeDifference < 3600000) {
            return false; // Hide the button if the scheduled time is within 1 hour
        }

        return true;
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className="main flex-1 bg-white">
                <div className="container-fluid">
                    <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">Social Media</h3>
                            <div className="dashboard innerpage smart-scroll">
                                <Link
                                    to={`/socialmedia/socialpost`}
                                    className="btn btn-green w-auto height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate">
                                    <span> Create New Post</span>
                                </Link>
                            </div>
                        </div>

                        <div className="job_internship_tab polartabsec px-4 mx-0">
                            <div className="xscroll p-0">
                                <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a style={{ textDecoration: 'none' }}>
                                            <button
                                                className={`nav-link ${state?.internal?.selectedTab === 'published' ? 'active' : ''}`}
                                                id="published-tab"
                                                type="button"
                                                role="tab"
                                                onClick={() => handleTabSelect('published')}>
                                                Published
                                            </button>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ textDecoration: 'none' }}>
                                            <button
                                                className={`nav-link ${state?.internal?.selectedTab === 'drafts' ? 'active' : ''}`}
                                                id="published-tab"
                                                type="button"
                                                role="tab"
                                                onClick={() => handleTabSelect('drafts')}>
                                                Drafts
                                            </button>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ textDecoration: 'none' }}>
                                            <button
                                                className={`nav-link ${state?.internal?.selectedTab === 'scheduled' ? 'active' : ''}`}
                                                id="scheduled-tab"
                                                type="button"
                                                role="tab"
                                                onClick={() => handleTabSelect('scheduled')}>
                                                Scheduled
                                            </button>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="JobInternshipListing">
                            <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="sent" role="tabpanel" aria-labelledby="sent-tab">
                                    <div className="social_wrap">
                                        <ul>
                                            {state?.data?.filtered?.map((data: any, index: any) => (
                                                <li key={index}>
                                                    <div className="post-view">
                                                        <div className="post-name">
                                                            <div className="social-logo">
                                                                <img
                                                                    src={
                                                                        data.display_image ||
                                                                        `${process.env.REACT_APP_IMAGE_BASEPATH}/${data?.network_type}.svg`
                                                                    }
                                                                    width="50"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                    data-bs-toggle="tooltip"
                                                                    title={data?.page_name}
                                                                />
                                                            </div>
                                                            <div className="post-title">
                                                                <p className="text-truncate">{data.post_title}</p>
                                                                {/* <div className="text-truncate" dangerouslySetInnerHTML={{ __html: data.post_message }} /> */}

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="post-detail">
                                                        <div className="post-detail-date">
                                                            {data?.status?.toLowerCase() == 'published' && (
                                                                <>
                                                                    {data.published_at_formatted}
                                                                    <span className="d-block">(Posted On)</span>
                                                                </>
                                                            )}

                                                            {data?.status?.toLowerCase() == 'draft' && (
                                                                <>
                                                                    {data.created_at_formatted}
                                                                    <span className="d-block">(Saved On)</span>
                                                                </>
                                                            )}

                                                            {data?.status?.toLowerCase() == 'scheduled' && (
                                                                <>
                                                                    {data.schedule_time_formatted}
                                                                    <span className="d-block">(To Be Posted On)</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        {/* <a href="javascript:;" className="btn btn-dark-blue w-auto height-40 px-3 text-white"><span>Re-Use</span></a> */}
                                                        {data?.status?.toLowerCase() == 'published' && (
                                                            <button
                                                                className={`btn btn-dark-blue w-auto height-40 px-3 text-white`}
                                                                id={`reuse-${data.id}`}
                                                                type="button"
                                                                onClick={() => handleReUse(data?.id)}>
                                                                Re-Use
                                                            </button>
                                                        )}

                                                        {data?.status?.toLowerCase() == 'draft' && (
                                                            <>
                                                                <button
                                                                    className={`btn btn-dark-blue w-auto height-40 px-3 text-white me-2`}
                                                                    id={`reuse-${data.id}`}
                                                                    type="button"
                                                                    onClick={() => navigate(`/socialmedia/socialpost/${data.id}`)}>
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    className={`btn btn-dark-blue w-auto height-40 px-3 text-white`}
                                                                    id={`reuse-${data.id}`}
                                                                    type="button"
                                                                    onClick={() => handleDiscard(data?.id, data.schedule_time, data?.status)}>
                                                                    Discard
                                                                </button>
                                                            </>
                                                        )}

                                                        {data?.status?.toLowerCase() === 'scheduled' && canDiscard(data.schedule_time) && (
                                                            <button
                                                                className={`btn btn-dark-blue w-auto height-40 px-3 text-white`}
                                                                id={`reuse-${data.id}`}
                                                                type="button"
                                                                onClick={() => handleDiscard(data?.id, data.schedule_time, data?.status)}>
                                                                Discard
                                                            </button>
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {state?.data?.filtered?.length <= 0 && (
                                            <div className='p-5 text-center'><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/no-data-found.svg" className='img-fluid' width={400} alt="" /></div>
                                        )}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SmartContext.Provider>
    );
}

export default SocialPostList;
