import { useState } from 'react';
import { axiosClient } from '../../../library/Service/axiosClient';

const UserUploadLayout = (props: any) => {
    const [file, setFile] = useState<File | null>(null);
    const [usertype, setUsertype] = useState('RAW_PROFILE');
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [uploadProgress, setUploadProgress] = useState(0); // Progress bar state
    const [responseMessage, setResponseMessage] = useState<{ processed: number; invalid: number } | null>(null); // Response state
    interface InvalidRecord {
        record: any;
        errors: string[];
    }

    const [invalidData, setInvalidData] = useState<InvalidRecord[]>([]);

    // Handle change in user type
    const handleUserTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUsertype(event.target.value); // Update state with selected user type
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!file) {
            console.error('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('usertype', usertype);

        const url = `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/raw-user/upload-spread-sheet`;

        try {
            setIsLoading(true); // Start loading
            setUploadProgress(0); // Reset progress

            let uploadResponse = await axiosClient().post(url, formData, {
                onUploadProgress: (progressEvent) => {
                    if (progressEvent && progressEvent.loaded !== undefined && progressEvent.total !== undefined) {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress); // Update progress
                    } else {
                        console.warn('Progress information is missing or incomplete.');
                        setUploadProgress(0); // Default to 0 if progress cannot be calculated
                    }
                },
            });
            console.log(uploadResponse.data);
            const { message, invalidRecords, data, invalidData } = uploadResponse.data;
            setInvalidData(invalidData || []);
            // Update response message state
            setResponseMessage({
                processed: data.length,
                invalid: invalidRecords,
            });

            console.log('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    return (
        <div className="container max-1140 px-lg-0 overflow-hidden">
            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="container mt-5">
                        <h2 className="mb-4">Upload Excel Data</h2>

                        <div className="alert alert-info">
                            <h5>Please Note:</h5>
                            <ul>
                                <li>The file must be in Excel format (.xlsx).</li>
                                <li>
                                    <strong>Institute ID</strong> and <strong>Program ID</strong> columns should not be blank.
                                </li>
                                <li>
                                    <strong>Email and Phone Number</strong> must be valid and should not be empty.
                                </li>
                            </ul>
                            <a href="https://company-logo-list.s3.ap-south-1.amazonaws.com/sample.xlsx" className="btn btn-link" download>
                                Download Sample Excel File
                            </a>
                        </div>

                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="user_type" className="form-label">
                                    Select User Type
                                </label>
                                <select className="form-control" name="user_type" id="user_type" onChange={handleUserTypeChange}>
                                    <option value="RAW_PROFILE">Raw User</option>
                                    <option value="SEMI_VERIFIED_USER">Semi-Verified User</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="fileInput" className="form-label">
                                    Choose file (Only Excel)
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="fileInput"
                                    accept=".xlsx"
                                    onChange={handleFileChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Uploading...' : 'Upload'}
                            </button>
                        </form>
                        {isLoading && (
                            <div className="progress mt-3">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${uploadProgress}%` }}
                                    aria-valuenow={uploadProgress}
                                    aria-valuemin={0}
                                    aria-valuemax={100}>
                                    {uploadProgress}%
                                </div>
                            </div>
                        )}
                        {responseMessage && (
                            <div className="mt-4">
                                <h5>Upload Summary</h5>
                                <p>
                                    <strong>Valid Records inserted in DB:</strong> {responseMessage.processed}
                                </p>
                                <p>
                                    <strong>Invalid Records:</strong> {responseMessage.invalid}
                                </p>
                            </div>
                        )}
                        {/* Conditionally show invalid data */}
                        {invalidData.length > 0 && (
                            <div>
                                <h3>Invalid Data</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Record</th>
                                            <th scope="col">Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invalidData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{JSON.stringify(item.record)}</td>
                                                <td>{item.errors.join(', ')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserUploadLayout;
