import { activateUserSession, isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    // let customValidationPassed = true;
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate || !isFormValid(state, dispatch))) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let teachingWork = state?.data['teachingWork'];
    if (Array.isArray(teachingWork)) {
        teachingWork = teachingWork.map((job) => {
            // Set default values for common properties
            const updatedJob = {
                ...job,
                recordTypeCode: isEmpty(job.recordTypeCode) ? 'JOINED' : job.recordTypeCode,
                jobCategory: 'TEACHING_AND_RESEARCH',
                isOutcome: state?.data?.selectedInstitute?.postProgramCompletionStatusCode === 'TEACHING_AND_RESEARCH',
                industryCode: 7,
                functionalAreaCode: 6,
            };

            // If the job is current, set endDate to null
            if (job.isCurrent) {
                updatedJob.endDate = null;
            }

            return updatedJob;
        });
    }

    let professionalPractice = state?.data['professionalPractice'];
    if (professionalPractice && typeof professionalPractice === 'object' && !Array.isArray(professionalPractice)) {
        if (Object.keys(professionalPractice).length === 0) {
            professionalPractice = [];
        } else {
            professionalPractice = [professionalPractice];
        }
    } else if (!Array.isArray(professionalPractice)) {
        professionalPractice = [];
    }

    let jobAppliedRecords = state?.data?.jobsApplied ?? [];
    let otherJobsRecords = state?.data?.jobs ?? []; // save othere Jobs like work page/working for compnay category
    
    let defenceWork = state?.data?.defenceWork ?? []; 
    defenceWork = defenceWork.map((defence:any) => {
        const { designationRank, ...rest } = defence; // Destructure `designationRank` to designation
        return {...rest, designation: designationRank};
    })

    let jobHistory = [...teachingWork, ...otherJobsRecords, ...jobAppliedRecords,...defenceWork];
    if (professionalPractice.length > 0) {
        jobHistory = [...jobHistory, ...professionalPractice];
    }
    // Send event to Moengage PG College
    // sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            })
            
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const teachingWork = state.data.teachingWork;

    teachingWork.forEach((teachingWork: any, index: number) => {
        if (isEmpty(teachingWork?.instituteId) && isEmpty(teachingWork?.otherInstitute)) {
            isFormInvalid = true;
            setError(`teachingWork.${index}.instituteId`, [`Please enter "Name of Institution"`], dispatch);
        } else {
            setError(`teachingWork.${index}.instituteId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'teachingWork', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.teachingWork.map((row: any, index: number) => {
        setError(`teachingWork.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'teachingWork', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const teachingWorkdata = state?.data['teachingWork'];
    let isFormValid = true;
    teachingWorkdata.forEach((teachingjob: any, index: number) => {
        console.log(teachingjob);

        if (isEmpty(teachingjob?.startDate)) {
            setError(`teachingWork.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`teachingWork.${index}.startDate`, [], dispatch);
        }

        if ((teachingjob?.isCurrent === false || isEmpty(teachingjob?.isCurrent)) && isEmpty(teachingjob?.endDate)) {
            setError(`teachingWork.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`teachingWork.${index}.endDate`, [], dispatch);
        }

        if (new Date(teachingjob?.startDate) >= new Date(teachingjob?.endDate)) {
            console.log('Invalid date');
        }

        if (teachingjob?.isCurrent === false || isEmpty(teachingjob?.isCurrent)) {
            if (isEmpty(teachingjob?.endDate) || new Date(teachingjob?.startDate) > new Date(teachingjob?.endDate)) {
                setError(`teachingWork.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`teachingWork.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};
