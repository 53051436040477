import { isEmpty, toSentenceCase } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';

class ManageProfileMarkerSyncService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        return Promise.all([
            ManageProfileMarkerSyncService.getProfileSyncData()
        ]).then((values) => {
            state.data = {
                instituteList : values[0].data
            };
            state.routeInfo = params;
            return Promise.resolve(state);
        });
    }

    

    static getProfileSyncData() {
        let localStorageData = localStorage.getItem('user');
        let userData = localStorageData ? JSON.parse(localStorageData) : null;
        const instituteId = userData?.institutes.join(',')
        return axiosClient().get(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/markers/get-marker-log-by-institute-id/${instituteId}`);
    }

    
}

export default ManageProfileMarkerSyncService;
