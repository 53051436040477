import { getValueFromSessionStore } from '../library/Core/SessionStore';
import { getDomainData } from '../library/Core/SmartFunctions';

class DomainDataService {
    static async get() {
        return { data: await getDomainData() };
    }
}

export default DomainDataService;
