import { useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const ColorPickerControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null);

    const readOnly =
        control.readOnly ||
        evaluateExpression(control.readOnlyExpression, state?.data, getControlValueFromState(parentDataKey as string, state as State));

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        if (isEmpty(data) && !isEmpty(control.props.defaultValue)) {
            handleControlValueChange({ control, value: control.props.defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, []);

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="color-picker-wrapper d-flex align-items-center">
                <input
                    id={control.id}
                    data-testid={control.id}
                    type="color"
                    disabled={readOnly ?? false}
                    className="form-control color-picker-input"
                    value={data || control.props.defaultValue || '#000000'}
                    onChange={(event) =>
                        handleControlValueChange({ control, value: event.target.value, dataKey, parentDataKey, state, dispatch })
                    }
                    ref={formControlRef}
                />
                <div
                    className="color-preview"
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: data || control.props.defaultValue || '#000000',
                        marginLeft: '10px',
                        border: '1px solid #ccc',
                    }}
                ></div>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default ColorPickerControl;
